import { environment } from '@environments/environment';
import { AuthService } from './../../../core/auth/_services/auth.service';
import { UserDTO } from './../dto/user-dto';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
	Headers,
	Http,
	Response,
	Request,
	RequestOptions,
	RequestOptionsArgs,
	XHRBackend
} from '@angular/http';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/reducers';
import { RefreshToken } from '@app/core/auth/_actions/auth.actions';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';


@Injectable()

export class HttpService extends Http {
	constructor(
		backend: XHRBackend,
		options: RequestOptions,
		public http: Http,
		private router: Router,
		private store: Store<AppState>,
		private auth: AuthService,
		private _http: HttpClient

	) {
		super(backend, options);
	}

	public request(
		url: string | Request,
		options?: RequestOptionsArgs
	): Observable<Response> {
		return super.request(url, options).pipe(
			catchError(response => {
				if (response.status === 401) {
				return this.auth.refreshToken().pipe(
						catchError(errToken => {
							console.error('La peticion ha fallado por favor valide el token!');
							this.router.navigate(['auth/login']);
							// this.router.navigate(['dashboard']);
							return of(errToken);
						}),
						mergeMap((res: Response) => {
							if (res.status === 403) {
								return of(res);
							} else {
								let responseToken = res.json();
								localStorage.setItem(
									environment.authTokenKey,
									responseToken.access_token
								);
								this.store.dispatch(
									new RefreshToken({
										token: responseToken.refresh_token
									})
								);
								return this.request(url, {
									...options,
									headers: new Headers({
										'Content-Type': 'application/json; charset=UTF-8',
										responseType: 'application/json',
										'Access-Control-Allow-Origin': '*',
										'Access-Control-Allow-Headers':
										'Origin, X-Requested-With, Content-Type, Accept',
										'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
										Authorization: 'Bearer ' + this.getToken(),
										username: this.getUser()
									})
								});
							}
						})
					);
				} else {
					return of(response);
				}
			})
		);
	}

	public handleError = (error: Response) => {
		if (error.status === 401) {
			//   this.router.navigate(['dashboard']);
			let token = localStorage.getItem('refresh_token');
			this.auth.refreshToken().subscribe((res: Response) => {
				if (res.status >= 400 && res.status <= 499) {
					console.error('La peticion ha fallado por favor valide el token!');
					this.router.navigate(['/map/dashboard']);
				} else {
					let response = res.json();
					localStorage.setItem(
						environment.authTokenKey,
						response.access_token
					);
					this.store.dispatch(new RefreshToken({ token }));
				}
			});
		}
		return of(error);
	}

	public getToken() {
		return localStorage.getItem(environment.authTokenKey);
	}

	public getUser() {
		let currentUser: string = '';
		try {
			const user = new UserDTO(
				JSON.parse(localStorage.getItem(environment.authUserKey))
			);
			currentUser = user.username;
		} catch {
			currentUser = '';
		}
		return currentUser;
	}

	ejecutarServicioGet(
		url: string,
		params?: HttpParams
	): Observable<Response> {
		return this.request(url, {
			search: params,
			method: 'get',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarServicioJsonP(url:string, callback:string = 'getJson'){

		return this._http.jsonp(url,callback);
	}

	ejecutarServicioGetData(
		url: string,
		data?
	) {

		const params = new HttpParams()
			.append('SERVICE', 'WFS')
			.append('VERSION', '1.0.0')
			.append('REQUEST', 'GetFeature')
			.append('typeName', 'Mineria:get_cod2')
			.append('maxFeatures', '50')
			.append('outputFormat', 'application/json');


		let headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('crossDomain', 'true')
			.set('method', 'PUT')
			.set('data', '{"viewparams": "poligono:-73.6452 5.2678\\,-73.6423 5.2648\\,-73.6358 5.2713\\,-73.6388 5.2743\\,-73.6388 5.2743\\,-73.6388 5.2743\\,-73.6407 5.2721\\,-73.6452 5.2678;modelo1:dsm_poligono_184_2019_prj;modelo2:dsm_poligono_184_2020_prj;metodo:Grilla;distvol:9;distcont:0;areainteres:titulo_minero;codarea:7777;fecha:2019-03-03"}') ;



		/*return this.request(url, {
			body: JSON.stringify(data),
			method: 'get',
			headers: headers
		});*/


		url = url + params.toString()
		let setvice = this._http.get(url, {headers: headers})
		console.log(setvice)
		return setvice;
	}

	ejecutarServicioGetCORS(
		url: string,
		params?: HttpParams
	): Observable<Response> {
		return this.request(url, {
			search: params,
			method: 'get',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}
	ejecutarServicioPost(url: string, data?): Observable<Response> {
		return this.request(`${url.toString()}`, {
			body: data,
			method: 'post',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}
	ejecutarServicioPostNoToken(url: string, data) {
		this.http.post(url, data, {
			headers: new Headers({
				'Content-Type': 'text/plain; charset=UTF-8',
			})
		}).subscribe();
	}

	ejecutarServicioPostFile(url: string, data?): Observable<Response> {
		return this.request(url, {
			body:data,
			method: 'post',
			headers: new Headers({
				'Content-Type': 'multipart/form-data; charset=UTF-8',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarServicioPostFile2(url: string, data?): Observable<Response> {
		return this._http.post<any>(url, data, {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}


	ejecutarServicioPut(url: string, data?): Observable<Response> {
		return this.request(url, {
			body: JSON.stringify(data),
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				dataType: 'jsonp',
				contentType: 'application/json',
				jsonpCallback: 'getJson',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarServicioDelete(url: string, data?): Observable<Response> {
		return this.request(url, {
			body: JSON.stringify(data),
			method: 'delete',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarCloudFunction(functionName: string, data?): Observable<Response> {
		let endpoint = environment.endpoints.meteosimAPI
		return this.ejecutarServicioPost(endpoint['base'] + endpoint['execCloudFunction'],
		{
			"function": functionName,
			"jsonData": data?data:""
		}
	)
	}

}


