import { BasicLayerDTO } from './basic-layer-dto';
import { TopicLayerDTO } from './topic-layer-dto';

export class ConfigDTO {

    topics: TopicLayerDTO[];
    basics: BasicLayerDTO[];
    default: BasicLayerDTO[];

    constructor(data?: any) {
        if (data !== undefined) {
            this.topics = data['topics'] !== undefined ? data['topics'] : null;
            this.basics = data['basics'] !== undefined ? data['basics'] : null;
            this.default = data['default'] !== undefined ? data['default'] : null;
        }
    }

    static fromJS(data: any): ConfigDTO {
        return new ConfigDTO(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['topics'] = this.topics !== undefined ? this.topics : null;
        data['basics'] = this.basics !== undefined ? this.basics : null;
        data['default'] = this.default !== undefined ? this.default : null;
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new ConfigDTO(JSON.parse(json));
    }
}
