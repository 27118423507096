import { LayerDTO } from './../../shared/dto/layer-dto';
import { ConfigDTO } from './../../shared/dto/config-dto';
import { environment } from './../../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Response } from '@angular/http';
import { HttpService } from './../../shared/services/http.service';
// import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { TopicLayerDTO } from '../../shared/dto/topic-layer-dto';
import { FilterDTO } from '../../shared/dto/filter-dto';
import { UIService } from "./UI.service";
import { map } from 'rxjs/operators';
import {layerWmtsDto} from '../../shared/dto/layer-wmts-dto'
@Injectable({
  providedIn: 'root'
})
export class CapaService {

  private _filters: FilterDTO[] = [];
  private _views: ConfigDTO;
  private _topic: TopicLayerDTO;
  private _basicLayers: LayerDTO[] = [];
  private _busqueda: any;
  private layerWmts:layerWmtsDto;
  private _busquedaWMS: any;
  private _resultadobusqueda: any;
  private _capaMarkerPopup: LayerDTO;
  private _featureSelected: any;
  private _modelodePrediccion: any;
  private _updatePredictionLayer: any;
  private _limpiarmodelodePrediccion: any;
  private _changeOpacity: any;
  private _takeMapSnapshot: any;
  private _returnMapSnapshot: any;
  private _capasPorDefecto: any;
  private _crossFilteringLayer: any;
  private _cleanCrossFilteringLayer: any;
  private _intersectionAndDifferenceLayer: any;
  private _cleanIntersectionAndDifferenceLayer: any;
  private _centerPolygon: any;
  private _centerElementsGroup: any;
  private _selectMultitemporalLayer: any;
  private _clearSelectMultitemporalLayer: any;
  private _multitemporalAnalysisLayer: any;
  private _clearMultitemporalAnalysisLayer: any;
  private _setIndustryLayerFilter: any;
  private _industryLayerFilter: any;
  private _pollutantsBuffer: any;
  private _minesWithinBufferLayer: any;
  private _cleanHighlightLayers: any;
  private _removePollutantSourceBufferLayers: any;
  private _applyFilterOnLayer: any;
  //private _viewsUrl: string = environment.endpoints.views;
  private _filtersUrl: string = environment.endpoints.filters;
  private _updateFiltersUrl: string = environment.endpoints.updateFilters;
  private _otherFilterResults: any = undefined;
  private _selectFilterResults: any = undefined;

  private selectedLayer:BehaviorSubject<any>=new BehaviorSubject(null);
  private selectedLayerParent:BehaviorSubject<any>=new BehaviorSubject(null);

  private selectedTopic:BehaviorSubject<any>=new BehaviorSubject(null);

  private filtersSource = new BehaviorSubject<any>(this._filters);
  public filtersSource$ = this.filtersSource.asObservable();

  private capaFilter:BehaviorSubject<any>=new BehaviorSubject<any>({});
  private flyToMap:Subject<any>=new Subject<any>();

  private addWMTSTypeLayerToMap:Subject<layerWmtsDto>=new Subject<layerWmtsDto>();

  private closeCapas:Subject<any>=new Subject<any>();

  private selectedPomca:BehaviorSubject<any>=new BehaviorSubject<any>({});

  private addPomcaLayer:BehaviorSubject<any>=new BehaviorSubject<any>({});
  private removePomcaLayer:BehaviorSubject<any>=new BehaviorSubject<any>({});

  private viewsSource = new BehaviorSubject<ConfigDTO>(this._views);
  public viewsSource$ = this.viewsSource.asObservable();

  private topicSource = new BehaviorSubject<TopicLayerDTO>(this._topic);
  public topicSource$ = this.topicSource.asObservable();

  private capasBasicasService = new BehaviorSubject<LayerDTO[]>(this._basicLayers);
  public capasBasicasService$ = this.capasBasicasService.asObservable();

  public busquedaService = new BehaviorSubject<any>(this._busqueda);
  public busquedaService$ = this.busquedaService.asObservable();

  public busquedaServiceWMS = new BehaviorSubject<any>(this._busquedaWMS);
  public busquedaServiceWMS$ = this.busquedaServiceWMS.asObservable();

  public capaMarkerPopup = new BehaviorSubject<any>(this._capaMarkerPopup);
  public capaMarkerPopup$ = this.capaMarkerPopup.asObservable();

  private capasPorDefecto = new BehaviorSubject<any>(this._capasPorDefecto);
  public capasPorDefecto$ = this.capasPorDefecto.asObservable();

  public resultadoBusquedaService = new BehaviorSubject<any>(this._resultadobusqueda);
  public resultadoBusquedaService$ = this.resultadoBusquedaService.asObservable();

  public featureSelected = new BehaviorSubject<any>(this._featureSelected);
  public featureSelected$ = this.featureSelected.asObservable();

  public modelodePrediccion = new BehaviorSubject<any>(this._modelodePrediccion);
  public modelodePrediccion$ = this.modelodePrediccion.asObservable();

  public updatePredictionLayer = new BehaviorSubject<any>(this._updatePredictionLayer);
  public updatePredictionLayer$ = this.updatePredictionLayer.asObservable();

  public limpiarmodelodePrediccion = new BehaviorSubject<any>(this._limpiarmodelodePrediccion);
  public limpiarmodelodePrediccion$ = this.limpiarmodelodePrediccion.asObservable();

  public changeOpacity = new BehaviorSubject<any>(this._changeOpacity);
  public changeOpacity$ = this.changeOpacity.asObservable();

  public takeMapSnapshot = new BehaviorSubject<any>(this._takeMapSnapshot);
  public takeMapSnapshot$ = this.takeMapSnapshot.asObservable();

  public returnMapSnapshot = new BehaviorSubject<any>(this._returnMapSnapshot);
  public returnMapSnapshot$ = this.returnMapSnapshot.asObservable();

  public crossFilteringLayer = new BehaviorSubject<any>(this._crossFilteringLayer);
  public crossFilteringLayer$ = this.crossFilteringLayer.asObservable();

  public cleanCrossFilteringLayer = new BehaviorSubject<any>(this._cleanCrossFilteringLayer);
  public cleanCrossFilteringLayer$ = this.cleanCrossFilteringLayer.asObservable();

  public intersectionAndDifferenceLayer = new BehaviorSubject<any>(this._intersectionAndDifferenceLayer);
  public intersectionAndDifferenceLayer$ = this.intersectionAndDifferenceLayer.asObservable();

  public cleanIntersectionAndDifferenceLayer = new BehaviorSubject<any>(this._cleanIntersectionAndDifferenceLayer);
  public cleanIntersectionAndDifferenceLayer$ = this.cleanIntersectionAndDifferenceLayer.asObservable();

  public centerPolygon = new BehaviorSubject<any>(this._centerPolygon);
  public centerPolygon$ = this.centerPolygon.asObservable();

  public centerElementsGroup = new BehaviorSubject<any>(this._centerElementsGroup);
  public centerElementsGroup$ = this.centerElementsGroup.asObservable();

  private turnOnOffLayer = new Subject<void>();
  public turnOnOffLayer$ = this.turnOnOffLayer.asObservable();
  
  private showSearchedGeoServerLayer = new Subject<void>();
  public showSearchedGeoServerLayer$ = this.showSearchedGeoServerLayer.asObservable();

  public selectMultitemporalLayer = new BehaviorSubject<any>(this._selectMultitemporalLayer);
  public selectMultitemporalLayer$ = this.selectMultitemporalLayer.asObservable();

  public clearSelectMultitemporalLayer = new BehaviorSubject<any>(this._clearSelectMultitemporalLayer);
  public clearSelectMultitemporalLayer$ = this.clearSelectMultitemporalLayer.asObservable();

  public multitemporalAnalysisLayer = new BehaviorSubject<any>(this._multitemporalAnalysisLayer);
  public multitemporalAnalysisLayer$ = this.multitemporalAnalysisLayer.asObservable();

  public clearMultitemporalAnalysisLayer = new BehaviorSubject<any>(this._clearMultitemporalAnalysisLayer);
  public clearMultitemporalAnalysisLayer$ = this.clearMultitemporalAnalysisLayer.asObservable();

  public industryLayerFilter = new BehaviorSubject<any>(this._industryLayerFilter);
  public industryLayerFilter$ = this.industryLayerFilter.asObservable();

  public minesWithinBufferLayer = new BehaviorSubject<any>(this._minesWithinBufferLayer);
  public minesWithinBufferLayer$ = this.minesWithinBufferLayer.asObservable();

  public setIndustryLayerFilter = new BehaviorSubject<any>(this._setIndustryLayerFilter);
  public setIndustryLayerFilter$ = this.setIndustryLayerFilter.asObservable();

  public pollutantsBuffer = new BehaviorSubject<any>(this._pollutantsBuffer);
  public pollutantsBuffer$ = this.pollutantsBuffer.asObservable();

  public cleanHighlightLayers = new BehaviorSubject<any>(this._cleanHighlightLayers);
  public cleanHighlightLayers$ = this.cleanHighlightLayers.asObservable();

  public removePollutantSourceBufferLayers = new BehaviorSubject<any>(this._removePollutantSourceBufferLayers);
  public removePollutantSourceBufferLayers$ = this.removePollutantSourceBufferLayers.asObservable();

  public applyFilterOnLayer = new BehaviorSubject<any>(this._applyFilterOnLayer);
  public applyFilterOnLayer$ = this.applyFilterOnLayer.asObservable();

  public otherFilterResults = new BehaviorSubject<any>(this._otherFilterResults);
  public otherFilterResults$ = this.otherFilterResults.asObservable();

  public selectFilterResults = new BehaviorSubject<any>(this._selectFilterResults);
  public selectFilterResults$ = this.selectFilterResults.asObservable();
  
  public bounds = new BehaviorSubject<any>(null);
  public bounds$ = this.bounds.asObservable();

  layerFilters = new Subject <any>();
  layerFilters$ = this.layerFilters.asObservable();

  constructor(
    private httpService: HttpService,
    private _UIService: UIService
  ) {
    // this.updateLayers();
  }
  get closeCapasObservable(){
    return this.closeCapas.asObservable();
  }
  set closeCapasObservableData(action:any){
    this.closeCapas.next(action);
  }
  get flyToMapObservable(){
    return this.flyToMap.asObservable();
  }
  set flyToMapObservableData(coordinates:any){
    this.flyToMap.next(coordinates);
  }
  get capaFilterObservable(){
    return this.capaFilter.asObservable();
  }
  set CapaFilterObservableData(capa:any){
    this.capaFilter.next(capa);
  }
  get WMTSLayerToMapObservable(){
    return this.addWMTSTypeLayerToMap.asObservable();
  }
  set WMTSLayerToMapData(layer:any){
    this.addWMTSTypeLayerToMap.next(layer);
  }

  get selectedPomcaObservable(){
    return this.selectedPomca.asObservable();
  }
  set selectedPomcaObservableData(pomca:any){
    this.selectedPomca.next(pomca);
  }
  get addPomcaLayerObservable(){
    return this.addPomcaLayer.asObservable();
  }
  set addPomcaLayerObservableData(data:any){
    this.addPomcaLayer.next(data);
  }
  get removePomcaLayerObservable(){
    return this.removePomcaLayer.asObservable();
  }
  set removePomcaLayerObservableData(pomca:any){
    this.removePomcaLayer.next(pomca);
  }
  
  updateLayers() {
    if (this._views === undefined) {
      this.httpService.ejecutarCloudFunction("pescar-get-menu-layers").subscribe((res: any) => {
        if (res.status === 200) {
          this._views = ConfigDTO.fromJS(res.json());
          // this._views = ConfigDTO.fromJS(dummyview);
          // console.log(this._views)
          this.viewsSource.next(this._views);

          //this.setActualTopic(this._views.topics[0]);
          //this.addBasicLayer(this._views.basics[2].layers[1]);
        } else {
          // TODO :: MANEJAR EXCEPCION
        }
      }, 
      err =>{
        console.log('Error: ', err);
      });
    }
  }

  get selecterLayerObservable(){
    return this.selectedLayer.asObservable();
  }
  selectedLayerObservableData(capa:any){
    this.selectedLayer.next(capa);
  }

  get selecterLayerParentObservable(){
    return this.selectedLayerParent.asObservable();
  }
  selectedLayerParentObservableData(capa:any){
    this.selectedLayerParent.next(capa);
  }

  get selecterTopicObservable(){
    return this.selectedTopic.asObservable();
  }

  setActualTopic(topic: TopicLayerDTO) {
    if (this._topic === topic) {
      this._topic = null;
    } else {
      this._topic = topic;
    }
    this._UIService.stopScript()
    this.topicSource.next(this._topic);
    this.selectedTopic.next(this._topic)
  }

  getFilters() {
    return this._filters;
  }

  resetFilters() {
    this._filters = [];
  }

  getActualTopic() {
    return this._topic;
  }

  getBasicsLayers() {
    return this._basicLayers;
  }

  addFilter(filter: FilterDTO) {

    let layerExist = false;
    for (let i = 0; i < this._filters.length; i++) {

      if (filter.layer_url === this._filters[i].layer_url) {
        this._filters[i].filters_by.push(filter.filters_by[0]);
        layerExist = true;
      }

    }

    if (!layerExist) {
      this._filters.push(filter);
    }

    // this.filtersSource.next(this._filters);
  }

  updateFilter(filters){
    console.log('updating filters service',filters)
    return this.httpService.ejecutarServicioPost(this._updateFiltersUrl, filters)
      
  }

  /*updateFilter(filter: FilterDTO) {

    let layerExist = false;
    for (let j = 0; j < this._filters.length; j++) {
      if (this._filters[j].layer_url === filter.layer_url) {
        for (let i = 0; i < this._filters[j].filters_by.length; i++) {

          if (filter.filters_by[0].value === this._filters[j].filters_by[i].value) {
            this._filters[j].filters_by[i].checked = filter.filters_by[0].checked;
          }

        }
      }
    }


     this.filtersSource.next(this._filters);
  }*/

  capaIsSelected(name: string) {
    let find = false;
    for (let i = 0; i < this._basicLayers.length; i++) {
      if (this._basicLayers[i].name === name) {
        find = true
      }
    }
    return find;
  }

  returnCapa(name: string) {
    let capa: any;
    for (let i = 0; i < this._basicLayers.length; i++) {
      if (this._basicLayers[i].name === name) {
        capa = this._basicLayers[i]
      }
    }
    return capa;
  }

  addBasicLayer(capa: LayerDTO) {
    let find = false;
    for (let i = 0; i < this._basicLayers.length; i++) {
      if (this._basicLayers[i].layer_id === capa.layer_id) {
        find = true
      }
    }
    if (find === false) {
      this._basicLayers.push(capa);
      this.capasBasicasService.next(this._basicLayers);
    }
  }

  reloadFilter(layer){
    return this.layerFilters.next(layer);
  }

  removeBasicLayer(capa: LayerDTO) {
    let index = this._basicLayers.findIndex(l=>l.layer_id == capa.layer_id);
    if(index !== -1){
      if(capa.hasOwnProperty("sub_layer")){
        capa["sub_layer"].forEach(element => {
          let subIndex = this._basicLayers.findIndex(l=>l.layer_id == element.layer_id);
          if(subIndex !== -1) {
            this._basicLayers.splice(subIndex, 1);
          }
        });
        this._basicLayers.splice(index, 1);
        this.capasBasicasService.next(this._basicLayers);
      } else {
        this._basicLayers.splice(index, 1);
        this.capasBasicasService.next(this._basicLayers);
      }
    }
  }

  removeFilter(filter: FilterDTO) {

    for (let i = 0; i < this._filters.length; i++) {

      if (filter.layer_url === this._filters[i].layer_url) {

        for (let j = 0; j < this._filters[i].filters_by.length; j++) {

          if (this._filters[i].filters_by[j].value === filter.filters_by[0].value) {
            this._filters[i].filters_by.splice(j, 1);
            j--;
          }
        }

        if (this._filters[i].filters_by.length === 0) {
          this._filters.splice(i, 1);
          i--;
        }
      }
    }

    // this.filtersSource.next(this._filters);
  }

  removeBasicLayerByArr(capa: LayerDTO[]) {
    for (let i = 0; i < this._basicLayers.length; i++) {
      let finded: boolean = false;
      for (let j = 0; j < capa.length; j++) {
        if (this._basicLayers[i].layer_id === capa[j].layer_id) {
          finded = true;
        }
      }
      if (finded === false) {
        this._basicLayers.splice(i, 1);
        i--;
      }
    }
    this.capasBasicasService.next(this._basicLayers);
  }

  resetBasicLayer() {
    this._basicLayers = [];
    this.capasBasicasService.next(this._basicLayers);
  }

  resetFeatureSelected(){
    this._featureSelected = undefined;
    this.featureSelected.next(this._featureSelected);
  }

  resetCapaMarkerPopup() {
    console.log('reseteando capamarkerpopuppp****** :>> ', );
    this._capaMarkerPopup = undefined;
    this.capaMarkerPopup.next(this._capaMarkerPopup);
  }

  getFiltersByLayer(params) {
    return this.httpService.ejecutarServicioPost(this._filtersUrl, params)
      .pipe(
        map(res => res.json())
      )
  }

  resetAll() {
    /*
    this.setActualTopic(null);
    this.resetBasicLayer();
    this.resetFeatureSelected();
    this.resetCapaMarkerPopup();
    */
    this.reset_filters();
    this.reset_views();
    this.reset_topic();
    this.reset_basicLayers();
    this.reset_busqueda();
    this.reset_busquedaWMS();
    this.reset_resultadobusqueda();
    this.reset_capaMarkerPopup();
    this.reset_featureSelected();
    this.reset_modelodePrediccion();
    this.reset_updatePredictionLayer();
    this.reset_limpiarmodelodePrediccion();
    this.reset_changeOpacity();
    this.reset_takeMapSnapshot();
    this.reset_returnMapSnapshot();
    this.reset_capasPorDefecto();
    this.reset_crossFilteringLayer();
    this.reset_cleanCrossFilteringLayer();
    this.reset_intersectionAndDifferenceLayer();
    this.reset_cleanIntersectionAndDifferenceLayer();
    this.reset_centerPolygon();
    this.reset_centerElementsGroup();
    this.reset_selectMultitemporalLayer();
    this.reset_clearSelectMultitemporalLayer();
    this.reset_multitemporalAnalysisLayer();
    this.reset_clearMultitemporalAnalysisLayer();
    this.reset_setIndustryLayerFilter();
    this.reset_industryLayerFilter();
    this.reset_pollutantsBuffer();
    this.reset_minesWithinBufferLayer();
    this.reset_cleanHighlightLayers();
    this.reset_otherFilterResults();
    this.reset_selectFilterResults();
    this.reset_removePollutantSourceBufferLayers();
    this.reset_applyFilterOnLayer();
  }

  pushSearchedLayer(capa:any) {
    return new Promise((resolve, reject) => {
      if (capa.parentLayer && capa.parentLayer.layer_id !== 'CUMI0021_Analisis_Determinantes_Ambientales'){
        this.turnOnOffLayer.next( capa );
      }
      if (capa['typeLayer'] === 'GeoServer') {
        this.showSearchedGeoServerLayer.next( capa );
      }
      resolve( true );
    });
    // this.searchedLayer.next( capa );
  }

  cleanHighlightLayersOnMap() {
    this.cleanHighlightLayers.next( true );
  }

  /*************************************************************************** */
  reset_filters() {
    this._filters = [];
    this.filtersSource.next(this._filters);
  }
  reset_views() {
      this._views = undefined;
      this.viewsSource.next(this._views);
  }
  reset_topic() {
      this._topic = undefined;
      this.topicSource.next(this._topic);
  }
  reset_basicLayers() {
      this._basicLayers = [];
      this.capasBasicasService.next(this._basicLayers);
  }
  reset_busqueda() {
      this._busqueda = undefined;
      this.busquedaService.next(this._busqueda);
  }
  reset_busquedaWMS() {
      this._busquedaWMS = undefined;
      this.busquedaServiceWMS.next(this._busquedaWMS);
  }
  reset_resultadobusqueda() {
      this._resultadobusqueda = undefined;
      this.resultadoBusquedaService.next(this._resultadobusqueda);
  }
  reset_capaMarkerPopup() {
      this._capaMarkerPopup = undefined;
      this.capaMarkerPopup.next(this._capaMarkerPopup);
  }
  reset_featureSelected() {
      this._featureSelected = undefined;
      this.featureSelected.next(this._featureSelected);
  }
  reset_modelodePrediccion() {
      this._modelodePrediccion = undefined;
      this.modelodePrediccion.next(this._modelodePrediccion);
  }
  reset_updatePredictionLayer() {
      this._updatePredictionLayer = undefined;
      this.updatePredictionLayer.next(this._updatePredictionLayer);
  }
  reset_limpiarmodelodePrediccion() {
      this._limpiarmodelodePrediccion = undefined;
      this.limpiarmodelodePrediccion.next(this._limpiarmodelodePrediccion);
  }
  reset_changeOpacity() {
      this._changeOpacity = undefined;
      this.changeOpacity.next(this._changeOpacity);
  }
  reset_takeMapSnapshot() {
      this._takeMapSnapshot = undefined;
      this.takeMapSnapshot.next(this._takeMapSnapshot);
  }
  reset_returnMapSnapshot() {
      this._returnMapSnapshot = undefined;
      this.returnMapSnapshot.next(this._returnMapSnapshot);
  }
  reset_capasPorDefecto() {
      this._capasPorDefecto = undefined;
      this.capasPorDefecto.next(this._capasPorDefecto);
  }
  reset_crossFilteringLayer() {
      this._crossFilteringLayer = undefined;
      this.crossFilteringLayer.next(this._crossFilteringLayer);
  }
  reset_cleanCrossFilteringLayer() {
      this._cleanCrossFilteringLayer = undefined;
      this.cleanCrossFilteringLayer.next(this._cleanCrossFilteringLayer);
  }
  reset_intersectionAndDifferenceLayer() {
      this._intersectionAndDifferenceLayer = undefined;
      this.intersectionAndDifferenceLayer.next(this._intersectionAndDifferenceLayer);
  }
  reset_cleanIntersectionAndDifferenceLayer() {
      this._cleanIntersectionAndDifferenceLayer = undefined;
      this.cleanIntersectionAndDifferenceLayer.next(this._cleanIntersectionAndDifferenceLayer);
  }
  reset_centerPolygon() {
      this._centerPolygon = undefined;
      this.centerPolygon.next(this._centerPolygon);
  }
  reset_centerElementsGroup() {
      this._centerElementsGroup = undefined;
      this.centerElementsGroup.next(this._centerElementsGroup);
  }
  reset_selectMultitemporalLayer() {
      this._selectMultitemporalLayer = undefined;
      this.selectMultitemporalLayer.next(this._selectMultitemporalLayer);
  }
  reset_clearSelectMultitemporalLayer() {
      this._clearSelectMultitemporalLayer = undefined;
      this.clearSelectMultitemporalLayer.next(this._clearSelectMultitemporalLayer);
  }
  reset_multitemporalAnalysisLayer() {
      this._multitemporalAnalysisLayer = undefined;
      this.multitemporalAnalysisLayer.next(this._multitemporalAnalysisLayer);
  }
  reset_clearMultitemporalAnalysisLayer() {
      this._clearMultitemporalAnalysisLayer = undefined;
      this.clearMultitemporalAnalysisLayer.next(this._clearMultitemporalAnalysisLayer);
  }
  reset_setIndustryLayerFilter() {
      this._setIndustryLayerFilter = undefined;
      this.setIndustryLayerFilter.next(this._setIndustryLayerFilter);
  }
  reset_industryLayerFilter() {
      this._industryLayerFilter = undefined;
      this.industryLayerFilter.next(this._industryLayerFilter);
  }
  reset_pollutantsBuffer() {
      this._pollutantsBuffer = undefined;
      this.pollutantsBuffer.next(this._pollutantsBuffer);
  }
  reset_minesWithinBufferLayer() {
      this._minesWithinBufferLayer = undefined;
      this.minesWithinBufferLayer.next(this._minesWithinBufferLayer);
  }
  reset_cleanHighlightLayers() {
      this._cleanHighlightLayers = undefined;
      this.cleanHighlightLayers.next(this._cleanHighlightLayers);
  }
  reset_otherFilterResults() {
    this._otherFilterResults = undefined;
    this.otherFilterResults.next(this._otherFilterResults);
  }
  reset_selectFilterResults() {
    this._selectFilterResults = undefined;
    this.selectFilterResults.next(this._selectFilterResults);
  }
  reset_removePollutantSourceBufferLayers() {
    this._removePollutantSourceBufferLayers = undefined;
    this.removePollutantSourceBufferLayers.next(this._removePollutantSourceBufferLayers);
  }
  reset_applyFilterOnLayer() {
    this._applyFilterOnLayer = undefined;
    this.applyFilterOnLayer.next(this._applyFilterOnLayer);
  }

  setBounds(bounds) {
    this.bounds.next(bounds);
  }

}


