// Angular
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'kt-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss', 
		//'../../../car/header/topbar/topbar.component.scss'
	],
})
export class NotificationsComponent implements OnInit, OnDestroy {

	@Input() isDashboard: boolean;

	private subscriptions: Subscription[] = [];

	optionActive: string = '';
	notifications: Array<any> = [];
	notificationsHistorical: Array<any> = [];
	newNotifications: number = 0;
	isOpen: boolean = false;
	counterShowMore: number = 0;
  	private intervalNotificationsUpdate: any = null;

	constructor(
		private router: Router,
		private http: HttpService,
		private cdr: ChangeDetectorRef
	) {
		this.getNotifications();
	}

	ngOnInit(): void {
		this.intervalNotificationsUpdate = setInterval(function(){
			//this.getNotifications();
			this.http.ejecutarServicioGet(environment.endpoints.notifications + 'notification/').subscribe( 
				response => {
					let res= response.json();
					if (res.length > 0) {
						this.newNotifications = res.filter(e => !e.read).length;
						if(this.isOpen) {
							for(let i = 0; i < res.length; i++) {
								let noti = res[i];
								let found = this.notifications.findIndex(e => e.id === noti.id);
								if(found === -1) {
									this.notifications.unshift(noti);
									this.sendRead();
								}
							}
						} else {
							this.notifications = res;
						}
					} else {
						this.newNotifications = 0;
						this.notifications = [];
					}
					// this.notifications = res.json();
					//console.log(this.notifications);
					this.cdr.markForCheck();
				},
				err => {
					console.log("Error: ", err);
				});
		}.bind(this), 10000);
	}

	ngOnDestroy() {
		clearInterval(this.intervalNotificationsUpdate);
	}

	getNotifications() {
		this.http.ejecutarServicioGet(environment.endpoints.notifications + 'notification/').subscribe( 
		res => {
			this.notifications = res.json();
			console.log(this.notifications);
			this.cdr.markForCheck();
			},
		err => {
			console.log("Error: ", err);
			});
	}

	toggled(event) {
		if (event) {
			this.isOpen = true;
			this.sendRead();
		} else {
			this.isOpen = false;
			this.counterShowMore = 0;
		}
	}

	sendRead() {
		if(this.notifications.length > 0) {
			let views = this.notifications.map( e => {
				return "" + e.id;
			});
			this.http.ejecutarServicioPost(environment.endpoints.notifications + 'notification/' +"read", views).subscribe( 
				response => {
					console.log("Actualización del estado de notificaciones correcta.")
				},
				err => {
					console.log("Error al actualizar el estado de las notificaciones: ", err);
				});
		}
	}

	navigate(url) {
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
   		this.router.navigate([url]));
	}

	showMore() {
		this.counterShowMore = this.counterShowMore + 1;
		if(this.counterShowMore === 1) {
			this.http.ejecutarServicioGet(environment.endpoints.notifications+ 'notification/'+"all").subscribe( 
				response => {
					let res= response.json();
					if (res.length > 0) {
						this.notificationsHistorical = res;
						for(let i = 0; i < this.notifications.length; i++) {
							let indexNoti = this.notificationsHistorical.findIndex(e => e.id === this.notifications[i].id);
							if ( indexNoti !== -1 ) {
								this.notificationsHistorical.splice(indexNoti, 1);
							}
						}
						let historicalLength = 0;
						if (this.notificationsHistorical.length>10) {
							historicalLength = 10;
						} else {
							historicalLength = this.notificationsHistorical.length;
						}
						for (let i = 0; i < historicalLength; i++) {
							this.notifications.push(this.notificationsHistorical.shift())
						}
					} else {
						this.notificationsHistorical = [];
					}
					this.cdr.markForCheck();
				},
				err => {
					console.log("Error: ", err);
				}
			);
		} else {
			if (this.notificationsHistorical.length > 0){
				let historicalLength = 0;
				if (this.notificationsHistorical.length>10) {
					historicalLength = 10;
				} else {
					historicalLength = this.notificationsHistorical.length;
				}
				for (let i = 0; i < historicalLength; i++) {
					this.notifications.push(this.notificationsHistorical.shift())
				}
			}
		}
	}
}
