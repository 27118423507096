import { PermissionLayerService } from './../../../views/car/_services/permission-layer.service';
import { Http, RequestOptionsArgs, Headers } from '@angular/http';
import { UserDTO } from './../../../views/shared/dto/user-dto';
import { environment } from './../../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AppState } from '../../reducers';
// import { CapaService } from '../../../views/car/_services/capa.service';
import { Store } from '@ngrx/store';
import { Logout } from '../_actions/auth.actions';
import * as _ from "lodash";
//import { WebsqlService } from '../../../views/car/_services/websql.service';
import { IdbService } from '@app/views/car/_services/indexedDB.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private _user: UserDTO;
	private permissions;
	private userServiceSource = new Subject<UserDTO>();
	public userService$ = this.userServiceSource.asObservable();

	constructor(
		private http: Http,
		private store: Store<AppState>,
		// private capasService: CapaService,
		private router: Router,
		//private _wsql : WebsqlService,
		private _idb : IdbService,
		private layerService: PermissionLayerService
	) {}

	login(username: string, password: string): Observable<any> {
		return this.http.post(environment.endpoints.login, {
			username: username,
			password: password
		});
	}

	refreshToken(): Observable<any> {
		let token = localStorage.getItem('refresh_token');
		return this.http.post(environment.endpoints.refreshToken, {
			refresh_token: token,
			grant_type: 'refresh_token'
		});
	}

	logout() {
		let currentUser = this.getUser();
		console.log(currentUser);

		//this._wsql.dropTable('windRose');
		this._idb.clear("windRose")
		let hds = new Headers({
			'Content-Type': 'application/json; charset=UTF-8',
			'Authorization': 'Bearer ' + localStorage.getItem(environment.authTokenKey),
			'username': currentUser.username
		});
		let params: RequestOptionsArgs = {
			headers: hds
		};
		this.http.post(environment.endpoints.logout, {}, params)
				 .subscribe(res => {
					this.setUser(null);
					this.clearPermissions();
					this.store.dispatch(new Logout());
					this.router.navigate(['auth/login']);
				 });

	}

	getUser(): UserDTO {
		if (localStorage.getItem(environment.authUserKey)) {
			const user = new UserDTO(
				JSON.parse(localStorage.getItem(environment.authUserKey))
			);
			this._user = user;
		}
		return this._user;
	}

	getToken(): string {
		return localStorage.getItem(environment.authTokenKey);
	}

	setUser(user: UserDTO) {
		console.log('user :', user);
		if (user !== null) {
			localStorage.setItem(environment.authUserKey, user.toJSON());
		} else {
			localStorage.removeItem(environment.authUserKey);
			localStorage.clear();
		}
		this.userServiceSource.next(this._user);
		this._user = user;
	}

	/**
	 * Obtiene los permisos del usuario mediante una promesa, guarda estos permisos en el localstorage.
	 * Retorna los permisos obtenidos
	 */
	getUPermissions() {
		const permisos = new Promise((resolve, reject) => {
			this.http.get(environment.endpoints.menu, {
				headers: new Headers({
					'Content-Type': 'application/json; charset=UTF-8',
					responseType: 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem(environment.authTokenKey),
					username: this.getUser()
				})
			}).toPromise().then(res => {
				let menu = res.json();
				let per = []
				let rols = []
				menu.menu.forEach( (item) => {
				  rols.push({ id: item.id, name: item.name })
				  item.items.forEach( (item) => {
					per.push(item)
				  });
				});
				console.log('rols =>', rols);
				console.log('per => ', per);

				console.log('menu :', menu);
				let roles: any = {
					capaAire : _.find(menu.menu, {id: '1'}),
					capaAgua: _.find(menu.menu, {id: '2'}),
					capaSuelos: _.find(menu.menu, {id: '3'}),
					capaMineria: _.find(menu.menu, {id: '4'}),
					capaFauna: _.find(menu.menu, {id: '5'}),
					capaFlora: _.find(menu.menu, {id: '6'}),
					capaResiduos: _.find(menu.menu, {id: '7'}),
					capaClima: _.find(menu.menu, {id: '8'}),
					basicas: _.find(menu.menu, {id: '9'}),
					usuarios: _.find(menu.menu, {id: '10'}),
					umbrales: _.find(menu.menu, {id: '11'}),
					casos: _.find(menu.menu, {id: '15'}),
					capaRecursosCAR: _.find(menu.menu, {id: '13'}),
					capaCulturaAmbiental: _.find(menu.menu, {id: '19'}),

				}

				this.permissions = {
					//Aire

					'Aire__0':  this.checkPermission( '206', per) ,
					'Dispersion_Inventario':  this.checkPermission( '207', per) ,
					'emission-permits':  this.checkPermission('208', per) ,
					'prios':  this.checkPermission( '209', per) ,
					//Agua
					'underground-water':  this.checkPermission( '210', per),
					'dumping-permits':  this.checkPermission( '211', per) ,
					'Nodos_Bochicar':  this.checkPermission( '212', per) ,
					'riverbed-occupations':  this.checkPermission( '238', per) ,
					'psmv':  this.checkPermission( '239', per) ,
					'Fuquene':  this.checkPermission( '249', per) ,
					'Agua__5_fuquene':  this.checkPermission( '250', per) ,
					'surface-water':  this.checkPermission( '240', per) ,
					'water-sanctioning':  this.checkPermission( '241', per) ,
					'Modelacion_Inventario': this.checkPermission( '256', per) ,
					'dumping-sewer': this.checkPermission( '281', per) ,
					'benefits': this.checkPermission( '282', per) ,
					'dumping-water': this.checkPermission( '283', per) ,
					'dumping-ground': this.checkPermission( '284', per) ,

					//Suelos
					'Predios_CAR': this.checkPermission( '213', per) ,
					'muestreo_suelos':  this.checkPermission( '271', per) ,

					//Mineria
					'CUMI0021_Solicitudes_de_Legalizacion':  this.checkPermission( '214', per) ,
					'CUMI0021_Solicitudes_de_Contratacion':   this.checkPermission( '215', per) ,
					'CUMI0021_Area_Reserva_Especial_Minera':   this.checkPermission( '216', per) ,
					'CUMI0021_Sancionatorios':   this.checkPermission( '217', per) ,
					'CUMI0021 Permisivos':   this.checkPermission( '218', per) ,
					'CUMI0029_Aerofotografia_2019':   this.checkPermission( '219', per) ,
					'Zonas_Compatibles_Res_1499':   this.checkPermission( '220', per) ,
					'Zonas_Compatibles_Res_2001':   this.checkPermission( '221', per) ,
					'Areas_Importancia_Estrategica':   this.checkPermission( '222', per) ,
					'CUMI0021_Analisis_Determinantes_Ambientales':   this.checkPermission( '223', per) ,
					'CUMI0023_Analisis_Multitemporal':   this.checkPermission( '224', per) ,
					'CUMI0029_Analisis_Uso_Areas_Descubiertas':   this.checkPermission( '225', per) ,
					'Punto_Foto_Control':  this.checkPermission( '242', per) ,
					modelo3D:  this.checkPermission( '261', per) ,
					'mapaCambios':   this.checkPermission( '262', per) ,
					'Mapa_base_almeidas_guatavita':   this.checkPermission( '262', per) ,
					'MOD3D01_Modelos3DSoacha': this.checkPermission( '261', per) ,

					//Fauna
					'wildlife-permisive': this.checkPermission( '226', per) ,
					'wildlife-sanctioning': this.checkPermission( '227', per) ,
					'Fototrampas': this.checkPermission( '228', per) ,
					'fototrampas-review':  this.checkPermission( '243', per) ,
					'avistamientos':  this.checkPermission( '244', per) ,

					//Flora
					'flora':  this.checkPermission( '229', per) ,
					'2':  this.checkPermission( '230', per) ,
					'Parques_CAR':  this.checkPermission( '231', per) ,
					'complejo_sumapaz':  this.checkPermission( '252', per) ,
					'reverva_bogota':  this.checkPermission( '252', per) ,
					//Residuos
					'RESPEL':  this.checkPermission( '232', per) ,
					'RUA':  this.checkPermission( '233', per) ,
					'management-companies':  this.checkPermission( '234', per) ,
					'improper-disposal':  this.checkPermission( '251', per) ,

					//Clima
					'CUCL0057_Monitoreo_Variables_Metereologicas':  this.checkPermission( '235', per) ,
					'Agua__5':  this.checkPermission( '236', per) ,
					'Prediccion_Climatica':  this.checkPermission( '237', per) ,
					'CUCL0048':  this.checkPermission( '245', per) ,

					//Recursos CAR
					'yellow_machinery':  this.checkPermission( '246', per) ,
					'heavy_machinery_zones':  this.checkPermission( '247', per) ,
					'Maquinaria': this.checkPermission( '247', per)  || this.checkPermission( '246', per),
					'SeguimientoObras': this.checkPermission( '254', per) ,

					//Cultura Ambiental
					'denuncias_ambientales': this.checkPermission( '253', per) ,

					//Capa Basicas
					'Áreas de importancia Ambiental':  this.checkPermission( '201', per) ,
					'Cuerpos Hidricos':  this.checkPermission( '202', per) ,
					'División Territorial':  this.checkPermission( '203', per) ,
					'Predios Jurisdicción CAR':  this.checkPermission( '204', per) ,
					'Pomcas':  this.checkPermission( '205', per) ,
					'Imagenes Raster':  this.checkPermission( '248', per) ,

					//Admin Usuarios
					perfiles:  this.checkPermission( '101', per) ,
					roles:  this.checkPermission( '102', per) ,
					usuarios:  this.checkPermission( '103', per) ,
					crearPerfil:  this.checkPermission( '104', per) ,
					crearRol:  this.checkPermission( '105', per) ,
					crearUsuario:  this.checkPermission( '106', per) ,
					editarUsuario:  this.checkPermission( '107', per) ,
					editarPerfil:  this.checkPermission( '139', per) ,
					editarRol:  this.checkPermission( '140', per) ,
					asignarIndicador:  this.checkPermission( '114', per) ,
					estadoIndicador:  this.checkPermission( '110', per) ,
					// Umbrales
					buscarUmbral:  this.checkPermission( '152', per) ,
					crearUmbral:  this.checkPermission( '113', per) ,
					//Casos
					crearCaso:  this.checkPermission( '117', per) ,
					verCaso:  this.checkPermission( '118', per) ,
					estadoCaso:  this.checkPermission( '122', per),
					//Pomca
					crearPomca:  this.checkPermission( '255', per) ,
					//Cargue geografico
					cargarServicio:  this.checkPermission( '258', per) ,
					editarServicio:  this.checkPermission( '259', per) ,
					listarServicio:  this.checkPermission( '260', per) ,
					interseccion:  this.checkPermission( '264', per) ,
					comparar:  this.checkPermission( '265', per) ,
					cargueTemporal:  this.checkPermission( '266', per) ,
					imprimir: this.checkPermission( '272', per) ,
					punto: this.checkPermission( '273', per) ,
					linea: this.checkPermission( '274', per) ,
					poligono: this.checkPermission( '275', per) ,
					extension: this.checkPermission( '276', per) ,
					puntosCoordenadas: this.checkPermission( '277', per) ,
					nubePuntos: this.checkPermission( '278', per) ,

					//Muestreo de Aguas
					'muestreoAguasSuperficiales':  this.checkPermission( '267', per) ,
					'muestreoAguasSubterraneas':  this.checkPermission( '268', per) ,
					'muestreoAguasPorCaptacion':  this.checkPermission( '269', per) ,
					'muestreoAguasResiduales':  this.checkPermission( '270', per) ,

				} ;

				if(this.permissions['Aire__0'] || this.permissions['Dispersion_Inventario'] || this.permissions['emission-permits'] || this.permissions['prios'] || this.permissions['Modelacion_Inventario'] ){
				   this.permissions.capaAire = true ;
				} else {
					this.permissions.capaAire = false ;
				}
				if(this.permissions['underground-water'] || this.permissions['dumping-permits'] || this.permissions['Nodos_Bochicar'] ||
				   this.permissions['riverbed-occupations'] || this.permissions['psmv'] || this.permissions['Fuquene'] ||
				   this.permissions['Agua__5_fuquene'] ||  this.permissions['surface-water'] || this.permissions['water-sanctioning'] ){
						this.permissions.capaAgua = true ;
				} else {
					this.permissions.capaAgua = false ;
				}
				if(this.permissions['Predios_CAR']){
					this.permissions.capaSuelos = true ;
				} else {
					this.permissions.capaSuelos = false ;
				}
				if(this.permissions['CUMI0021_Solicitudes_de_Legalizacion'] || this.permissions['CUMI0021_Solicitudes_de_Contratacion'] || this.permissions['CUMI0021_Area_Reserva_Especial_Minera'] ||
					this.permissions['CUMI0021_Sancionatorios'] || this.permissions['CUMI0021 Permisivos'] || this.permissions['CUMI0029_Aerofotografia_2019'] ||
					this.permissions['Zonas_Compatibles_Res_1499'] || this.permissions['Zonas_Compatibles_Res_2001'] || this.permissions['Areas_Importancia_Estrategica'] ||
					this.permissions['CUMI0021_Analisis_Determinantes_Ambientales'] || this.permissions['CUMI0023_Analisis_Multitemporal'] || this.permissions['CUMI0029_Analisis_Uso_Areas_Descubiertas'] ||
					this.permissions['Punto_Foto_Control']){
						 this.permissions.capaMineria = true ;
				} else {
					this.permissions.capaMineria = false ;
				}
				if(this.permissions['wildlife-permisive'] || this.permissions['wildlife-sanctioning'] || this.permissions['Fototrampas'] ||
					this.permissions['fototrampas-review'] || this.permissions['avistamientos']){
						 this.permissions.capaFauna = true ;
				} else {
					this.permissions.capaFauna = false ;
				}

				if(this.permissions['flora'] || this.permissions['2'] || this.permissions['Parques_CAR'] || this.permissions['complejo_sumapaz']){
					this.permissions.capaFlora = true ;
				} else {
					this.permissions.capaFlora = false ;
				}

				if(this.permissions['RESPEL'] || this.permissions['RUA'] || this.permissions['management-companies'] ||
				   this.permissions['improper-disposal']){
					this.permissions.capaResiduos = true ;
				} else {
					this.permissions.capaResiduos = false ;
				}

				if(this.permissions['CUCL0057_Monitoreo_Variables_Metereologicas'] || this.permissions['Agua__5'] || this.permissions['Prediccion_Climatica'] ||
				   this.permissions['CUCL0048']){
					this.permissions.capaClima = true ;
				} else {
					this.permissions.capaClima = false ;
				}

				if(this.permissions['yellow_machinery'] || this.permissions['heavy_machinery_zones']){
					this.permissions.capaRecursosCAR = true ;
				} else {
					this.permissions.capaRecursosCAR = false ;
				}

				if (this.permissions['Áreas de importancia Ambiental'] || this.permissions['Cuerpos Hidricos'] || this.permissions['División Territorial'] ||
					this.permissions['Predios Jurisdicción CAR'] || this.permissions['Pomcas'] || this.permissions['Imagenes Raster']) {
					this.permissions.basicas = true;
				} else {
					this.permissions.basicas = false;
				}

				if (this.permissions.perfiles || this.permissions.roles || this.permissions.usuarios ||
					this.permissions.crearPerfil || this.permissions.crearRol || this.permissions.crearUsuario ||
					this.permissions.editarUsuario || this.permissions.editarPerfil || this.permissions.editarRol ||
					this.permissions.asignarIndicador || this.permissions.estadoIndicador) {
					this.permissions.adminUsuarios = true;
				} else {
					this.permissions.adminUsuarios = false;
				}

				if (this.permissions.buscarUmbral || this.permissions.crearUmbral) {
					this.permissions.umbrales = true;
				} else {
					this.permissions.umbrales = false;
				}

				if (this.permissions.crearCaso || this.permissions.verCaso || this.permissions.estadoCaso) {
					this.permissions.casos = true;
				} else {
					this.permissions.casos = false;
				}

				if (this.permissions['denuncias_ambientales']) {
					this.permissions.capaCulturaAmbiental = true;
				} else {
					this.permissions.capaCulturaAmbiental = false;
				}

				console.log('this.permissions :', this.permissions);
				localStorage.setItem("permissions", JSON.stringify(this.permissions));
				resolve(true)
			}).catch(err => {
				console.error(err)
				reject(false);
			});
		});
		return permisos;
	}

	checkPermission(id, per){
		return per.find(item => item.id === id)?true:false
	}

	/**
	 * Obtiene los permisos del usuario mediante una suscripción, guarda estos permisos en el localstorage.
	 */
	getUserPermissions (){
		this.http.get(environment.endpoints.menu, {
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem(environment.authTokenKey),
				username: this.getUser()
			})
		}).subscribe(res => {
			let menu = res.json();
			let per = []
		    let rols = []
		    menu.menu.forEach( (item) => {
		      rols.push({ id: item.id, name: item.name })
		      item.items.forEach( (item) => {
		        per.push(item)
		      });
			});
			// console.log('rols =>', rols);
			// console.log('per => ', per);
			// console.log('menu :', menu);


			const permissionLayer = per.map(p => p.id);

			// console.log(permissionLayer);
			this.layerService.setLayer(permissionLayer, this.getToken(), this.getUser());
			let roles: any = {
				capaAire : _.find(menu.menu, {id: '1'}),
				capaAgua: _.find(menu.menu, {id: '2'}),
				capaSuelos: _.find(menu.menu, {id: '3'}),
				capaMineria: _.find(menu.menu, {id: '4'}),
				capaFauna: _.find(menu.menu, {id: '5'}),
				capaFlora: _.find(menu.menu, {id: '6'}),
				capaResiduos: _.find(menu.menu, {id: '7'}),
				capaClima: _.find(menu.menu, {id: '8'}),
				basicas: _.find(menu.menu, {id: '9'}),
				usuarios: _.find(menu.menu, {id: '10'}),
				umbrales: _.find(menu.menu, {id: '11'}),
				casos:  _.find(menu.menu, {id: '15'}),
				capaRecursosCAR: _.find(menu.menu, {id: '13'}),
				capaCulturaAmbiental: _.find(menu.menu, {id: '19'}),
			}

			this.permissions = {
				//Aire
				'Aire__0':  this.checkPermission( '206', per) ,
				'Dispersion_Inventario':  this.checkPermission( '207', per) ,
				'emission-permits':  this.checkPermission('208', per) ,
				'prios':  this.checkPermission( '209', per) ,
				//Agua
				'underground-water':  this.checkPermission( '210', per),
				'dumping-permits':  this.checkPermission( '211', per) ,
				'Nodos_Bochicar':  this.checkPermission( '212', per) ,
				'riverbed-occupations':  this.checkPermission( '238', per) ,
				'psmv':  this.checkPermission( '239', per) ,
				'Fuquene':  this.checkPermission( '249', per) ,
				'Agua__5_fuquene':  this.checkPermission( '250', per) ,
				'surface-water':  this.checkPermission( '240', per) ,
				'water-sanctioning':  this.checkPermission( '241', per) ,
				'Modelacion_Inventario': this.checkPermission( '256', per) ,
				'dumping-sewer': this.checkPermission( '281', per) ,
				'benefits': this.checkPermission( '282', per) ,
				'dumping-water': this.checkPermission( '283', per) ,
				'dumping-ground': this.checkPermission( '284', per) ,

				//Suelos
				'Predios_CAR': this.checkPermission( '213', per) ,
				'muestreo_suelos':  this.checkPermission( '271', per) ,

				//Mineria
				'CUMI0021_Solicitudes_de_Legalizacion': this.checkPermission( '214', per) ,
				'CUMI0021_Solicitudes_de_Contratacion':  this.checkPermission( '215', per) ,
				'CUMI0021_Area_Reserva_Especial_Minera':  this.checkPermission( '216', per) ,
				'CUMI0021_Sancionatorios':  this.checkPermission( '217', per) ,
				'CUMI0021 Permisivos':  this.checkPermission( '218', per) ,
				'CUMI0029_Aerofotografia_2019':  this.checkPermission( '219', per) ,
				'Zonas_Compatibles_Res_1499':  this.checkPermission( '220', per) ,
				'Zonas_Compatibles_Res_2001':  this.checkPermission( '221', per) ,
				'Areas_Importancia_Estrategica':  this.checkPermission( '222', per) ,
				'CUMI0021_Analisis_Determinantes_Ambientales':  this.checkPermission( '223', per) ,
				'CUMI0023_Analisis_Multitemporal':  this.checkPermission( '224', per) ,
				'CUMI0029_Analisis_Uso_Areas_Descubiertas':  this.checkPermission( '225', per) ,
				'Punto_Foto_Control': this.checkPermission( '242', per) ,
				modelo3D:  this.checkPermission( '261', per) ,
				'mapaCambios':  this.checkPermission( '262', per) ,
				'MOD3D01_Modelos3DSoacha': this.checkPermission( '261', per) ,

				//Fauna
				'wildlife-permisive': this.checkPermission( '226', per) ,
				'wildlife-sanctioning': this.checkPermission( '227', per) ,
				'Fototrampas': this.checkPermission( '228', per) ,
				'fototrampas-review':  this.checkPermission( '243', per) ,
				'avistamientos':  this.checkPermission( '244', per) ,

				//Flora
				'flora':  this.checkPermission( '229', per) ,
				'2':  this.checkPermission( '230', per) ,
				'Parques_CAR':  this.checkPermission( '231', per) ,
				//Residuos
				'RESPEL':  this.checkPermission( '232', per) ,
				'RUA':  this.checkPermission( '233', per) ,
				'management-companies':  this.checkPermission( '234', per) ,
				'improper-disposal':  this.checkPermission( '251', per) ,
				'complejo_sumapaz':  this.checkPermission( '252', per) ,
				'reverva_bogota':  this.checkPermission( '252', per) ,

				//Clima
				'CUCL0057_Monitoreo_Variables_Metereologicas':  this.checkPermission( '235', per) ,
				'Agua__5':  this.checkPermission( '236', per) ,
				'Prediccion_Climatica':  this.checkPermission( '237', per) ,
				'CUCL0048':  this.checkPermission( '245', per) ,

				//Recursos CAR
				'yellow_machinery':  this.checkPermission( '246', per) ,
				'heavy_machinery_zones':  this.checkPermission( '247', per) ,
				'Maquinaria': this.checkPermission( '247', per)  || this.checkPermission( '246', per),
				'SeguimientoObras': this.checkPermission( '254', per) ,

				//Cultura Ambiental
				'denuncias_ambientales': this.checkPermission( '253', per) ,

				//Capa Basicas
				'Áreas de importancia Ambiental':  this.checkPermission( '201', per) ,
				'Cuerpos Hidricos':  this.checkPermission( '202', per) ,
				'División Territorial':  this.checkPermission( '203', per) ,
				'Predios Jurisdicción CAR':  this.checkPermission( '204', per) ,
				'Pomcas':  this.checkPermission( '205', per) ,
				'Imagenes Raster':  this.checkPermission( '248', per) ,

				//Admin Usuarios
				perfiles:  this.checkPermission( '101', per) ,
				roles:  this.checkPermission( '102', per) ,
				usuarios:  this.checkPermission( '103', per) ,
				crearPerfil:  this.checkPermission( '104', per) ,
				crearRol:  this.checkPermission( '105', per) ,
				crearUsuario:  this.checkPermission( '106', per) ,
				editarUsuario:  this.checkPermission( '107', per) ,
				editarPerfil:  this.checkPermission( '139', per) ,
				editarRol:  this.checkPermission( '140', per) ,
				asignarIndicador:  this.checkPermission( '114', per) ,
				estadoIndicador:  this.checkPermission( '110', per) ,
				// Umbrales
				buscarUmbral:  this.checkPermission( '152', per) ,
				crearUmbral:  this.checkPermission( '113', per) ,
				//Casos
				crearCaso: this.checkPermission( '117', per),
				verCaso: this.checkPermission( '118', per),
				estadoCaso: this.checkPermission( '122', per),
				//Pomca
				crearPomca:  this.checkPermission( '255', per) ,
				//Cargue geografico
				cargarServicio:  this.checkPermission( '258', per) ,
				editarServicio:  this.checkPermission( '259', per) ,
				listarServicio:  this.checkPermission( '260', per) ,
				interseccion:  this.checkPermission( '264', per) ,
				comparar:  this.checkPermission( '265', per) ,
				cargueTemporal:  this.checkPermission( '266', per) ,
				imprimir: this.checkPermission( '272', per) ,
				punto: this.checkPermission( '273', per) ,
				linea: this.checkPermission( '274', per) ,
				poligono: this.checkPermission( '275', per) ,
				extension: this.checkPermission( '276', per) ,
				puntosCoordenadas: this.checkPermission( '277', per) ,
				nubePuntos: this.checkPermission( '278', per) ,

				//Muestreo de Aguas
				'muestreoAguasSuperficiales':  this.checkPermission( '267', per) ,
				'muestreoAguasSubterraneas':  this.checkPermission( '268', per) ,
				'muestreoAguasPorCaptacion':  this.checkPermission( '269', per) ,
				'muestreoAguasResiduales':  this.checkPermission( '270', per) ,


			};

			if(this.permissions['Aire__0'] || this.permissions['Dispersion_Inventario'] || this.permissions['emission-permits'] || this.permissions['prios'] || this.permissions['Modelacion_Inventario'] ){
				this.permissions.capaAire = true ;
			} else {
				this.permissions.capaAire = false ;
			}
			if(this.permissions['underground-water'] || this.permissions['dumping-permits'] || this.permissions['Nodos_Bochicar'] ||
				this.permissions['riverbed-occupations'] || this.permissions['psmv'] || this.permissions['Fuquene'] ||
				this.permissions['Agua__5_fuquene'] ||  this.permissions['surface-water'] || this.permissions['water-sanctioning'] ){
				this.permissions.capaAgua = true ;
			} else {
				this.permissions.capaAgua = false ;
			}
			if(this.permissions['Predios_CAR']){
				this.permissions.capaSuelos = true ;
			} else {
				this.permissions.capaSuelos = false ;
			}
			if(this.permissions['CUMI0021_Solicitudes_de_Legalizacion'] || this.permissions['CUMI0021_Solicitudes_de_Contratacion'] || this.permissions['CUMI0021_Area_Reserva_Especial_Minera'] ||
				this.permissions['CUMI0021_Sancionatorios'] || this.permissions['CUMI0021 Permisivos'] || this.permissions['CUMI0029_Aerofotografia_2019'] ||
				this.permissions['Zonas_Compatibles_Res_1499'] || this.permissions['Zonas_Compatibles_Res_2001'] || this.permissions['Areas_Importancia_Estrategica'] ||
				this.permissions['CUMI0021_Analisis_Determinantes_Ambientales'] || this.permissions['CUMI0023_Analisis_Multitemporal'] || this.permissions['CUMI0029_Analisis_Uso_Areas_Descubiertas'] ||
				this.permissions['Punto_Foto_Control']){
				this.permissions.capaMineria = true ;
			} else {
				this.permissions.capaMineria = false ;
			}
			if(this.permissions['wildlife-permisive'] || this.permissions['wildlife-sanctioning'] || this.permissions['Fototrampas'] ||
				this.permissions['fototrampas-review'] || this.permissions['avistamientos']){
				this.permissions.capaFauna = true ;
			} else {
				this.permissions.capaFauna = false ;
			}

			if(this.permissions['flora'] || this.permissions['2'] || this.permissions['Parques_CAR'] || this.permissions['complejo_sumapaz']){
				this.permissions.capaFlora = true ;
			} else {
				this.permissions.capaFlora = false ;
			}

			if(this.permissions['RESPEL'] || this.permissions['RUA'] || this.permissions['management-companies'] ||
				this.permissions['improper-disposal']){
				this.permissions.capaResiduos = true ;
			} else {
				this.permissions.capaResiduos = false ;
			}

			if(this.permissions['CUCL0057_Monitoreo_Variables_Metereologicas'] || this.permissions['Agua__5'] || this.permissions['Prediccion_Climatica'] ||
				this.permissions['CUCL0048']){
				this.permissions.capaClima = true ;
			} else {
				this.permissions.capaClima = false ;
			}

			if(this.permissions['yellow_machinery'] || this.permissions['heavy_machinery_zones']){
				this.permissions.capaRecursosCAR = true ;
			} else {
				this.permissions.capaRecursosCAR = false ;
			}

			if (this.permissions['Áreas de importancia Ambiental'] || this.permissions['Cuerpos Hidricos'] || this.permissions['División Territorial'] ||
				this.permissions['Predios Jurisdicción CAR'] || this.permissions['Pomcas'] || this.permissions['Imagenes Raster']) {
				this.permissions['basicas'] = true;
			} else {
				this.permissions['basicas'] = false;
			}

			if (this.permissions.perfiles || this.permissions.roles || this.permissions.usuarios ||
				this.permissions.crearPerfil || this.permissions.crearRol || this.permissions.crearUsuario ||
				this.permissions.editarUsuario || this.permissions.editarPerfil || this.permissions.editarRol ||
				this.permissions.asignarIndicador || this.permissions.estadoIndicador) {
				this.permissions.adminUsuarios = true;
			} else {
				this.permissions.adminUsuarios = false;
			}

			if (this.permissions.buscarUmbral || this.permissions.crearUmbral) {
				this.permissions.umbrales = true;
			} else {
				this.permissions.umbrales = false;
			}

			if (this.permissions.crearCaso || this.permissions.verCaso || this.permissions.estadoCaso) {
				this.permissions.casos = true;
			} else {
				this.permissions.casos = false;
			}

			if (this.permissions['denuncias_ambientales']) {
				this.permissions.capaCulturaAmbiental = true;
			} else {
				this.permissions.capaCulturaAmbiental = false;
			}

			console.log('this.permissions :', this.permissions);
			localStorage.setItem("permissions", JSON.stringify(this.permissions));
		})
	}

	clearPermissions(){
		localStorage.removeItem("permissions");
		// Limpia el storage de los videos clasificados en fototrampas
		localStorage.removeItem("videoID");
		localStorage.clear();
		this.permissions = null;
	}

	getPermissions() {
		if (localStorage.getItem("permissions")) {
			const permission = JSON.parse(localStorage.getItem("permissions")
			);
			this.permissions = permission;
		}
		return this.permissions;
	}
	redirectHome(router: Router) {
		if (!this.getUser()) {
			router.navigate(['auth/login']);
			return;
		}

		router.navigate(['/map/dashboard']);
		/*
      switch ( this.getUser().roles[0] ) {
        case environment.rol.mapa :
          router.navigate(['dashboard']);
          break;
        case environment.rol.operario :
        case environment.rol.administrador :
          router.navigate(['administrador']);
          break;
        default:
          router.navigate(['error/404']);
          break;
      }
      */
	}
}
