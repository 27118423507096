import {Injectable} from '@angular/core';
//import { openDB } from 'idb/with-async-ittr-cjs';
import { openDB, deleteDB, wrap, unwrap } from 'idb';
import {Observable, Subject} from 'rxjs';
// import {Schedule} from '../app.component';
@Injectable({
  providedIn: 'root'
})
export class IdbService {
	private _dataChange: Subject<any> = new Subject<any>();
	private _dbPromise;
  constructor() {
  		this.connectToIDB();
   }
	  	async connectToIDB() {
	  	this._dbPromise = openDB('pescarDB', 1, {
	  	upgrade(db) {
		    db.createObjectStore('windRose');
		  },
		});
	}
	async set(table, key, val) {
		return (await this._dbPromise).put(table, val, key);
	}
	async get(table,key) {
		return (await this._dbPromise).get(table, key);
	}
	async delete(table,key) {
		return (await this._dbPromise).delete(table, key);
	}
	async clear(table) {
	    return (await this._dbPromise).clear(table);
	}
	async keys(table) {
	    return (await this._dbPromise).getAllKeys(table);
	}
}