export const environment = {
	production: true,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	authUserKey: 'authus8g1465sdf4ew16gc222d5f7eg51h2c3',
	version: '2.2.3',
	ambiente: 'prod',

	rol: {
		operario: 'Operativo',
		administrador: 'Administrativo',
		mapa: 'Mapa',
	},

	folders: {
		imgMarkers: '/assets/media/icons/markers/'
	},

	endpoints: {
		parent: 'https://pescar-air-dot-smart-helios-pescar.appspot.com',
		configLayerBochicar: 'https://pescar-filemanager-dot-smart-helios-pescar.appspot.com/file/download/text?bucket=sh-pescar-etl&file=',
		configLayers: 'https://pescar-filemanager-dot-smart-helios-pescar.appspot.com/file/download/text?bucket=pescar-map-layers-prod&file=',
		filters: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/filter_layer/key_values',
		updateFilters: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/filter_layer/apply_filters',
		statistic: 'https://pescar-statistics-dot-smart-helios-pescar.appspot.com/statistics/',
		graphOld: 'https://1-1-2-dot-pescar-statistics-dot-smart-helios-pescar.appspot.com/graph/',
		graph: 'https://pescar-statistics-dot-smart-helios-pescar.appspot.com/graph/',
		photoTraps: 'https://pescar-fototrampas-dot-smart-helios-pescar.appspot.com/',
		exceedingThresholds: 'https://storage.googleapis.com/smart-helios-meteosim-prod/alerts/',
		notifications: 'https://pescar-notification-dot-smart-helios-pescar.appspot.com/',
		permissionsLayer : 'https://pescar-helpdesk-dot-smart-helios-pescar.appspot.com/permissions/precise',
		mapsCar: 'https://storage.googleapis.com/pescar-map-layers/maps/car/',

		admin: {
			indicators: 'https://pescar-indicators-dot-smart-helios-pescar.appspot.com/indicators/list',
			indicator: 'https://pescar-indicators-dot-smart-helios-pescar.appspot.com/indicator',
			layersList: 'https://pescar-indicators-dot-smart-helios-pescar.appspot.com/layers/list',
			indicatorsByLayer: 'https://pescar-indicators-dot-smart-helios-pescar.appspot.com/layer',
			users: 'https://car-security-dot-smart-helios-pescar.appspot.com/users',
			user: 'https://car-security-dot-smart-helios-pescar.appspot.com/user',
			profiles: 'https://car-security-dot-smart-helios-pescar.appspot.com/user_profiles',
			profile: 'https://car-security-dot-smart-helios-pescar.appspot.com/user_profile',
			permissions: 'https://car-security-dot-smart-helios-pescar.appspot.com/user_permissions',
			roles: 'https://car-security-dot-smart-helios-pescar.appspot.com/user_roles',
			role: 'https://car-security-dot-smart-helios-pescar.appspot.com/user_role',
			thresholds: 'https://pescar-thresholds-dot-smart-helios-pescar.appspot.com/thresholds',
			thresholds_all: 'https://pescar-thresholds-dot-smart-helios-pescar.appspot.com/thresholds/all',
			modules: 'https://pescar-thresholds-dot-smart-helios-pescar.appspot.com/modules',
			helpdesk : 'https://pescar-helpdesk-dot-smart-helios-pescar.appspot.com',
			cases : 'https://pescar-helpdesk-dot-smart-helios-pescar.appspot.com/cases',
			entry : 'https://pescar-helpdesk-dot-smart-helios-pescar.appspot.com/entry',
			reports: 'https://pescar-reports-dot-smart-helios-pescar.appspot.com',
			modulesList: 'https://pescar-filemanager-dot-smart-helios-pescar.appspot.com/file/download/text?bucket=car_help_desk&file=',
			CFbigQueryBochicar: 'https://us-central1-smart-helios-pescar.cloudfunctions.net/save__record_to_bigquery',
			getSamplesSingelia:'https://us-central1-smart-helios-pescar.cloudfunctions.net/singelia_get_samples',
		},

		statistics: {
			airQualityMatch: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/listado_estaciones/statistics/',
			miningProcessingRequestStates: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/sae/mining/statistics/permissive_states/',
			miningProcessingRequestFilters: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/sae/mining/statistics/expedients/processing_request/filters?',
			miningProcessingSanctioningStates: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/sae/mining/statistics/penalty_classes/',
			miningProcessingSanctioningFilters: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/sae/mining/statistics/expedients/penalty_processes/filters?',
			airQualityStations: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/listado_estaciones/',
			weatherStations: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/meteorological/'
		},

		aplicar: {
			getDenunciasPorEstado: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDenunciasPorEstado?',
			getDenunciasPorTiempoClasificacion: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDenunciasPorTiempoFinalizacion?',
			getDenunciasPorClasificacion: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDenunciasPorClasificacion?',
			getDenunciasPorFormaFinalizacion: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDenunciasPorFormaFinalizacion?',
			getDenunciasPorMunicipio: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDenunciasPorMunicipio?',
			getDenunciasPorMunicipioTiempo: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDenunciasPorMunicipioTiempo?',
			getInfoDenuncias:  'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getInfoDenuncias?',
			getListadoRegionales: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getListadoRegionales',
			getListadoMunicipios: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getListadoMunicipios',
			getListadoClasificaciones: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getListadoClasificaciones',
			getListadoTotales :'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getListadoTotales?',

			getPresupuestoEjecutadoPomcas: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getPresupuestoEjecutadoPomcas?',
			getPorcentajeEjecutadoPomcas: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getPorcentajeEjecutadoPomcas?',
			getDetallePomcas: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getDetallePomcas?',
			getTotalesPomcas: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getTotalesPomcas?',

			getIdMuestrasAguaPotable: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getIdMuestrasAguaPotable?',
			getProgramasAguaPotable: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getProgramasAguaPotable?',
			getMunicipiosAguaPotable: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getMunicipiosAguaPotable?',
			getRegionalesAguaPotable: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getRegionalesAguaPotable?',
			getIdMuestrasAguaSuperficial: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getIdMuestrasAguaSuperficial?',
			getProgramasAguaSupercicial: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getProgramasAguaSupercicial?',
			getMunicipiosAguaSuperficial: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getMunicipiosAguaSuperficial?',
			getRegionalesAguaSuperficial: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getRegionalesAguaSuperficial?',
			getIdMuestrasAguaSubterranea: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getIdMuestrasAguaSubterranea?',
			getProgramasAguaSubterranea: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getProgramasAguaSubterranea?',
			getMunicipiosAguaSubterranea: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getMunicipiosAguaSubterranea?',
			getRegionalesAguaSubterranea: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/aplicar/getRegionalesAguaSubterranea?'
		},

		listStation: {
			base: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/listado_estaciones/',
			ica: '/ica'
		},

		geoServer: 'https://geo.pescar-car.co/geoserver',
		geoServerRoot: 'https://geo.pescar-car.co/',
		hostMineria: 'https://pescar-car.co',
		hostMineriaProcesos : 'http://35.223.200.22:8600',
		esriServer: 'https://sig.car.gov.co',
		fichaDendrologica: 'http://fichasdendrologicas.car.gov.co',
		hostMineriaHTTPPath : {
		  imgDefault: "/geoserver/Mineria/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&exceptions=application/vnd.ogc.se_inimage&SRS=EPSG:900913&WIDTH=500&HEIGHT=500&LAYERS=Mineria:vol_grilla,Mineria:volumen_result&STYLES=volumen_grilla_relleno,volumen_result&CQL_FILTER=id_lista_vol IN ('20230309021941');id_lista IN ('20230309021941')&BBOX=-8219187.216921082,561824.8003862952,-8219033.373384804,561922.583139257",
		  AreasDescubiertasCalculoV: '/geoserver/Mineria/ows?service=WFS&version=2.0.0&request=GetFeature&typeName=Mineria:AreasDescubiertasCalculoV&srsName=EPSG:4326&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom, ',
		  metadata: '/geoserver/Mineria/ows?service=WFS&version=2.0.0&request=GetFeature&typeName=Mineria:metadata&srsName=EPSG:4326&outputFormat=application%2Fjson&cql_filter=INTERSECTS(geom, POLYGON((',
		  GetMap: '/geoserver/Mineria/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&exceptions=application/vnd.ogc.se_inimage&SRS=EPSG:900913&WIDTH=500&HEIGHT=500',
		  GetMapSource: '/geoserver/Mineria/ows?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=application/vnd.mapbox-vector-tile',
		  GetMapContour_level: '/geoserver/Mineria/ows?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=application/vnd.mapbox-vector-tile&LAYERS=Mineria:contour_level&exceptions=application/vnd.ogc.se_inimage',
		  GetFeature: '/geoserver/Mineria/ows?service=WFS&version=2.0.0&request=GetFeature&typeName=Mineria:',
		  AreasDescubiertas: 'geoserver/Mineria/ows?service=WFS&version=2.0.0&request=GetFeature&typeName=Mineria:GetAreasDescubiertas&srsName=EPSG:4326&outputFormat=application/json'
		},

		login: 'https://car-security-dot-smart-helios-pescar.appspot.com/login',
		refreshToken: 'https://car-security-dot-smart-helios-pescar.appspot.com/oauth/access_token',
		logout: 'https://car-security-dot-smart-helios-pescar.appspot.com/logout',
		menu: 'https://car-security-dot-smart-helios-pescar.appspot.com/user/menu',

		meteosimAPI: {
			base: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/meteosim/api/',
			setHysplitTraj: 'setHysplitTraj',
			getHysplitTraj: 'getHysplitTraj',
			setHysplitDisp: 'setHysplitDisp',
			getHysplitDisp: 'getHysplitDisp',
			getTabularPrediction: 'getTabularPrediction',
			getNewTabularPrediction: 'getNewTabularPrediction',
			getTabularProfiles: 'getTabularProfiles',
			getNewTabularProfiles: 'getNewTabularProfiles',
			getWindRose: 'getWindRose',
			getNewWindRose: 'getNewWindRose',
			getColormap: 'getColormap',
			getColorAlerts: 'getColorAlerts',
			checkJobStatus: 'checkJobStatus',
			runCalpost: 'runCalpost',
			runCalpuff : 'runCalpuff',
			getColorBar: 'getColorBar',
			getWaterSamples: 'getWaterSamples',
			CFget_files_hymodelc: 'https://us-central1-smart-helios-pescar.cloudfunctions.net/get_files_hymodelc',
			CFfetch_calpost_files: 'https://us-central1-smart-helios-pescar.cloudfunctions.net/fetch_calpost_files',
			convert_geojson_to_shapefile: 'https://us-central1-smart-helios-pescar.cloudfunctions.net/convert_geojson_to_shapefile',
			bucket_meteosim: 'https://storage.cloud.google.com/smart-helios-meteosim-prod/shapefiles',
			getComplaintInfo: 'getComplaintInfo',
			savePollutants: 'savePollutants',
			getPomcaLayer: 'getPomcaLayer',
			getAreaInteres: 'getAreaInteres',
			execCloudFunction: 'execCloudFunction'
		},

		meteosimWMS: {
			base: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/meteosim/wms/',
			getArchive: 'getArchive',
			getFeature: 'getFeature',
			getColorScale: 'getColorScale',
			getPredictionDates: 'getPredictionDates'
		},

		meteosimReports: {
			base: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/meteosim/reports/',
			getHysplitTraj: 'getHysplitTraj',
			getHysplitDisp: 'getHysplitDisp',
			getMapReport: 'getMapReport',
			getMapReportDenunciasAmbientales: 'getMapReportDenunciasAmbientales',
			getMapReportBochicar: 'getMapReportBochicar',
			getModelingSources: 'getModelingSources',
			getPomcaReport: 'getPomcaReport',
			getDrawPrintPdf: 'getDrawPrintPdf',
			getMapaCambios: 'getMapaCambios',
			getIntersectionPolygonPdf: 'getIntersectionPolygonPdf',
			getIntersectionCapaPdf: 'getIntersectionCapaPdf'
		},

		miningReports: {
			base: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/mining/analysis/',
			environmental_determinants: 'environmental_determinants/report',
			use_discovered_areas: 'use_discovered_areas/report',
			export_environmental_determinants: 'environmental_determinants/export/xlsx?',
			export_use_discovered_areas: 'use_discovered_areas/export/xlsx?'
		},

		saeExpDetail: "https://pescar-air-dot-smart-helios-pescar.appspot.com/sae",
		saeReports: "https://pescar-air-dot-smart-helios-pescar.appspot.com/sae/reports/",

		weatherStations: "https://pescar-air-dot-smart-helios-pescar.appspot.com/meteorological/stations/",

		bochicar: {
			base: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/bochicar/',
			getStreamURL: "node/{node}/camera/{camera}",
			getStream: "https://car-videostreaming-dot-smart-helios-pescar.appspot.com/image_feed?url=",
			getInfoBitacorasBochicar: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/bochicar/getInfoBitacorasBochicar?',
			getInfoBitacorasBochicarDashboard: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/bochicar/getInfoBitacorasDashboard?',
			getTotalRegistrosBochicar: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/bochicar/getTotalRegistrosBochicar?',
			getMayorNroCasosBochicar: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/bochicar/getMayorNroCasosBochicar?',
			getDatosGraficasBochicar: 'https://pescar-air-dot-smart-helios-pescar.appspot.com/bochicar/getDatosGraficasBochicar?'
		},

		helpdesk: {
			base: 'https://pescar-helpdesk-dot-smart-helios-pescar.appspot.com/',
			meteosimSearch: 'meteosim-simulation/search',
			meteosimUpdate: 'meteosim-simulation/update_state',
			meteosimDelete: 'meteosim-simulation/remove',
			meteosimCreate: 'meteosim-simulation/create'
		}

	},

	mapbox: {
		accessToken: 'pk.eyJ1IjoicGVzY2FyIiwiYSI6ImNsa2lqenRqMDBpOTEzcXA0Z3lteHhzdG4ifQ.zY1cdpIcy3WAH3Hq6FIU9A'
	},
};
