import { AuthService } from './../../../../../../core/auth/_services/auth.service';
import { UserDTO } from './../../../../dto/user-dto';
import { environment } from './../../../../../../../environments/environment';
// Angular
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { CapaService } from '../../../../../car/_services/capa.service';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<UserDTO>;
	user: UserDTO;
	version: string = environment['version'];
	ambiente: string = environment['ambiente'];

	@Input() showAvatar: boolean = true;
	@Input() showHi: boolean = true;
	@Input() showBadge: boolean = false;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private authService: AuthService,
		private capasService: CapaService,
		private cdr: ChangeDetectorRef
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.authService.userService$;
		this.user = this.authService.getUser();
		// this.user$ = this.store.pipe(select(currentUser));
	}

	/**
	 * Log out
	 */
	logout() {
		this.authService.logout();
		// Limpiar Capas
		this.capasService.resetAll();
		this.cdr.markForCheck();
	}
}
