import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _modo: string;
  private _lang: string;

  constructor() {
    this._modo = 'car';
    this._lang = 'es_car';
  }

  public getModo() {
    return this._modo;
  }

  public getLanguage() {
    return this._lang;
  }

}
