import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kt-color-changing-svg',
  templateUrl: './color-changing-svg.component.html',
  styleUrls: ['./color-changing-svg.component.scss']
})
export class ColorChangingSvgComponent implements OnInit {
  color: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.color = params['color'] || '#000';
    });
  }

}
