import {Component, OnInit, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {NgbModalConfig, NgbModal, NgbActiveModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-modal-mensaje',
	templateUrl: './modal-mensaje.component.html',
	styleUrls: ['./modal-mensaje.component.scss'],
	providers: [NgbModalConfig, NgbModal]
})
export class ModalMensajeComponent implements OnInit {
	@ViewChild('content') contentRef: any;

	@Input('content')
	set content(varsContent) {
	}

	@Input('config')
	set config(varConfig) {
		if (varConfig['status'] > 0) {
			this.configModal = varConfig;
			this.open();
		}
	}

	@Output() statusModal = new EventEmitter();

	sthsOption: number = 1;
	configModal: Object;
	configModal1: Object;
	configModal2: Object;
	configModal3: Object;
	configModal4: Object;
	options: any = {
		centered: true,
		size: 'lg',
		windowClass: 'sths-modal-style',
		backdropClass: 'sths-backdrop'
	};

	constructor(config: NgbModalConfig,
				private modalService: NgbModal,
				public activeModal: NgbActiveModal) {
		config.backdrop = 'static';
		config.keyboard = false;

		this.configModal1 = {
			icono: 'categorias',
			titulo: 'Se ha asociado exitosamente',
			textos: [
				'Los datos del grupo de dispositivo ha sido guardado con éxito',
				'Generando creación de credencial...'
			],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'ok'
				}
			]
		};

		this.configModal2 = {
			icono: 'categorias',
			titulo: 'Regenerar credencial',
			textos: [
				'Generando creación de credencial'
			],
			botones: [
				{
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelar'
				},
				{
					label: 'Aceptar',
					class: '',
					accion: 'aceptar'
				}
			]
		};

		this.configModal3 = {
			icono: 'categorias',
			titulo: 'Se ha asociado exitosamente',
			textos: [
				'Los datos del dispositivo creado ha sido guardado con éxito'
			],
			botones: [
				{
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelar'
				},
				{
					label: 'Añadir',
					class: '',
					accion: 'anadir'
				}
			]
		};

		this.configModal4 = {
			icono: 'eliminar',
			titulo: 'Eliminar Operador',
			textos: [
				'¿Esta seguro que quiere eliminar esta Operador?'
			],
			botones: [
				{
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelar'
				},
				{
					label: 'Eliminar',
					class: '',
					accion: 'eliminar'
				}
			]
		};
	}

	ngOnInit() {
	}

	open() {
		setTimeout(() => {
			this.modalService.open(this.contentRef, this.options).result.then((result) => {
				// Cerrar
				this.statusModal.emit('');
			}, (reason) => {
				// Cerrar
				this.statusModal.emit(reason);
			});
		}, 300);
	}

	accionBoton(accion) {
		this.modalService.dismissAll(accion);
	}

}
