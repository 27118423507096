import { AuthService } from './../../../../../core/auth/_services/auth.service';
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// Auth
import { AuthNoticeService, Login } from '../../../../../core/auth';
import { environment } from './../../../../../../environments/environment';
import { UserDTO } from '../../../dto/user-dto';
// capa
import { CapaService } from './../../../../../views/car/_services/capa.service';
import { UIService } from './../../../../car/_services/UI.service';
import { RefreshToken } from '@app/core/auth/_actions/auth.actions';


@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Modales
	showInvalidCredentials: number = -1;
	configModalInvalidCredentials: {} = { status: -1 };

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private capasService: CapaService,
		private uiService: UIService
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {

		let username = '';
		let password = '';

		/*if ( environment.production === false ) {
			username = 'juan.perez';
			password = '1234567';
		}*/
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use account
			<strong>${username}</strong> and password
			<strong>${password}</strong> to continue.`;
			this.authNoticeService.setNotice(initialNotice, 'info');
		}

		this.loginForm = this.fb.group({
			username: [username, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [password, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		// this.capasService.resetAll();
		// const controls = this.loginForm.controls;
		// /** check form */
		// if (this.loginForm.invalid) {
		// 	Object.keys(controls).forEach(controlName =>
		// 		controls[controlName].markAsTouched()
		// 	);
		// 	return;
		// }

		// this.loading = true;
		// this.auth
		// 	.login(controls['username'].value, controls['password'].value)
		// 	.subscribe((response: Response) => {
		// 		this.loading = false;
		// 		const user = new UserDTO(response.json());
		// 		if (user) {
		// 			this.auth.setUser(user);
		// 			this.store.dispatch(new Login({ authToken: user.access_token }));
		// 			this.router.navigateByUrl(this.returnUrl); // Main page
		// 		} else {
		// 			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
		// 		}
		// 	});

		this.capasService.resetAll();
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		this.auth
			.login(controls['username'].value, controls['password'].value)
			.subscribe((response: Response) => {
				//Valida que no deje ingresar a usuarios con perfil del formulario de vertimientos
				if(response['_body']) {
					if (JSON.parse(response['_body']).roles[0] == 'Vertimientos') {
						this.uiService.showMessage("Perfil no autorizado");
						this.loading = false;
						this.cdr.markForCheck();
						return;
					}
				}
				switch (response.status) {
					case 200:
						const user = new UserDTO(response.json());
						if (user) {
							this.auth.setUser(user);
							//this.auth.getUserPermissions();
							this.store.dispatch(new Login({ authToken: user.access_token }));
							this.store.dispatch(new RefreshToken({ token: user.refresh_token }));
							this.router.navigateByUrl(this.returnUrl); // Main page
							return;
						}
						break;
					case 401:
						this.invalidCredentials();
						break;
					default:
						this.uiService.showMessage(response.json()['message']);
						if (response.json()['message'] === 'Se debe cambiar la contraseña') {
							this.router.navigateByUrl('/auth/change-password?user=' + controls['username'].value); // Change password
							return;
						}
						break;
				}

				this.loading = false;
				this.cdr.markForCheck();
			}, (error: Response) => {
				this.loading = false;
				if (error.json()['message'] === "User Not Found") {
					this.invalidCredentials();
				}
				else if (error.json()['message'] === 'Se debe cambiar la contraseña') {
					this.router.navigateByUrl('/auth/change-password?user=' + controls['username'].value); // Change password
				}
				else if (error.json()['message'] === "Credentials Do Not Match") {
					this.invalidCredentials();
				}
				else {
					this.uiService.showMessage(error.json()['message']);
					this.loading = false;
					this.cdr.markForCheck();
				}
			});// END POST
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	invalidCredentials() {
		this.showInvalidCredentials = 1;
		this.configModalInvalidCredentials = {
			status: 1,
			icono: 'smart-icon-laptop-x',
			titulo: 'Error de Usuario o contraseña',
			textos: [
				'El nombre de usuario y/o la contraseña que ingresaste no coinciden con nuestros registros. <br> Por favor revisa e inténtalo de nuevo.'
			],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'ok'
				},
			]
		};
		this.cdr.markForCheck();
	}
}
