import { LayoutUtilsService, MessageType } from './../../../core/_base/crud/utils/layout-utils.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable,Output, EventEmitter,HostListener } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class UIService {

  private _actualDialog: MatSnackBarRef<any>;

  private _selectedTopMenu: string = '';
  private selectedTopMenu = new BehaviorSubject<{}>(this._selectedTopMenu);
  public selectedTopMenu$ = this.selectedTopMenu.asObservable();

  private _selectAccordionStats: number = 0;
  private selectAccordionStats = new BehaviorSubject<{}>(this._selectAccordionStats);
  public selectAccordionStats$ = this.selectAccordionStats.asObservable();

  isOpenMarkerPopUp = false;

  // @Output() change: EventEmitter<boolean> = new EventEmitter();
  public change = new BehaviorSubject<boolean>(false);
  change$ = this.change.asObservable();


  runingScript() {
    this.isOpenMarkerPopUp = true;
    this.change.next(this.isOpenMarkerPopUp);
  }
  stopScript() {
    this.isOpenMarkerPopUp = false
    this.change.next(this.isOpenMarkerPopUp);
  }

  screenHeight:any
	screenWidth:any


  // @Output() change: EventEmitter<boolean> = new EventEmitter();
  public width = new BehaviorSubject<any>(0)
  width$ = this.width.asObservable();
  @HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenHeight = window.innerHeight;
    this.width.next(window.innerWidth);
	}

  
  constructor(private layoutUtilsService: LayoutUtilsService) {
    this.onResize()
  }

  setActualSelectedTopMenu(selectedTopMenu) {
    this._selectedTopMenu = selectedTopMenu;
    this.selectedTopMenu.next(this._selectedTopMenu);
  }

  getActualSelectedTopMenu() {
    return this._selectedTopMenu;
  }

  setActualSelectedAccordionStats(selectedAccordionStats) {
    this._selectAccordionStats = selectedAccordionStats;
    this.selectAccordionStats.next(this._selectAccordionStats);
  }

  getActualSelectedAccordionStats() {
    return this._selectAccordionStats;
  }

  showMessage(message, position?, duration?, messageType?, showCancelButton = true, showUndoButton = false, durationUndoButton = 0) {
    if (messageType === undefined) { messageType = MessageType.Update; }
    if (this._actualDialog) { this._actualDialog.dismiss(); }
    this._actualDialog = this.layoutUtilsService.showActionNotification(message, messageType, duration, true, showUndoButton, durationUndoButton, position);
    return this._actualDialog;
  }

  downloadFileBase64(data: any, type: string, name: string) {
    let docType = '';
    switch (type) {
      case 'pem':
        docType = 'application/x-pem-file';
        break;
      case 'csv':
        docType = 'text/csv';
        break;
      case 'xls':
        docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'xlsx':
        docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'pdf':
        docType = 'application/pdf';
        break;
      case 'zip':
        docType = 'application/zip';
        break;
      default:
        docType = 'text/plain';
        break;
    }

    let element = document.createElement('a');
    element.setAttribute('href', 'data:' + docType + ';base64,' + encodeURIComponent(data));
    element.setAttribute('download', name + '.' + (type === 'xls' ? 'xlsx' : type) );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

   /* let pdfWindow = window.open("")
    pdfWindow.document.write(
    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    encodeURI(data) + "'></iframe>"
    )*/

  }

  openPDFBase64 (data) {
    var byteCharacters = atob(data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
  }

}
