import { BehaviorSubject } from 'rxjs';
import { HttpService } from './../../shared/services/http.service';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionLayerService {

  private layers = [];
  layers$ = new BehaviorSubject<any>(this.layers);
  private url = environment.endpoints.permissionsLayer;
  constructor(private http: HttpClient) { }

  private setHeader(token, user) {
    return new HttpHeaders({
        Authorization: 'Bearer ' + token,
        username: user
      });
  }

  setLayer(permissions, token, user) {
    const headers = this.setHeader(token, user);
    this.http.post(`${this.url}`, permissions, { headers })
    .toPromise()
    .then((res: any) => {
      console.log('layers from new permissions module => ', res);
      this.layers = res;
      this.layers$.next(res);
    });
  }

  getLayers() {
    return this.layers;
  }
}
