// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators,
	FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../../../../core/auth';
import { HttpService } from '../../../services/http.service';
import { environment } from './../../../../../../environments/environment';
import { UIService } from '../../../../car/_services/UI.service';

@Component({
	selector: 'kt-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
	// Public params
	changePasswordForm: FormGroup;
	loading = false;
	errors: any = [];
	email: string = '';

	private unsubscribe: Subject<any>;

	// Modales
	showChangeModal: number = -1;
	configChangeModal: {} = { status: -1 };

	private username: string;

	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private uiService: UIService,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
		this.route.queryParams.subscribe(params => {
			this.username = params['user'];
		});
	}

	ngOnInit() {
		this.initChangeForm();
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	initChangeForm() {
		this.changePasswordForm = new FormGroup({
			passwordTemp: new FormControl('', [
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			passwordRepeat: new FormControl('', [
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
		});
	}

	changeInput(input: any): any {
		input.type = input.type === 'password' ? 'text' : 'password';
	}

	submit() {
		const controls = this.changePasswordForm.controls;
		/** check form */
		if (this.changePasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		if (controls['password'].value !== controls['passwordRepeat'].value) {
			this.uiService.showMessage(
				'La contraseñas no coinciden. Por favor digitelas nuevamente'
			);
			return;
		}

		let params = {
			username: this.username,
			temporalPassword: controls['passwordTemp'].value,
			newPassword: controls['password'].value
		};
		this.http
			.ejecutarServicioPost(
				`${environment.endpoints.admin.user}/newpassword`,
				params
			)
			.subscribe(
				(response: Response) => {
					switch (response.status) {
						case 200:
							this.changeSuccess();
							break;
						default:
							this.uiService.showMessage(
								response.json()['message']
							);
							break;
					}

					this.loading = false;
					this.cdr.markForCheck();
				},
				(error: Response) => {
					this.loading = false;
					this.uiService.showMessage(error.json()['message']);
					this.cdr.markForCheck();
				}
			);
	}

	changeStatus() {
		this.showChangeModal = -1;
		this.cdr.markForCheck();

		this.router.navigateByUrl(`/auth/login`);
	}

	changeSuccess() {
		this.showChangeModal = 1;
		this.configChangeModal = {
			status: 1,
			icono: 'smart-icon-check',
			titulo: 'Contraseña',
			textos: [`Se cambió exitosamente la contraseña`],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'ok'
				}
			]
		};
		this.cdr.markForCheck();
	}
}
