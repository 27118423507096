// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../../../../core/auth';
import { HttpService } from '../../../services/http.service';
import { environment } from './../../../../../../environments/environment';
import { UIService } from './../../../../car/_services/UI.service';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];
	email: string = '';

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	// Modales
	showForgotModal: number = -1;
	configForgotModal: {} = { status: -1 };

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private uiService: UIService
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initForgotForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initForgotForm() {
		this.forgotPasswordForm = this.fb.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.email,
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		this.email = controls['email'].value;

		this.http
			.ejecutarServicioPost(`${environment.endpoints.admin.user}/${this.email}`, null)
			.subscribe((response: Response) => {
							switch (response.status) {
								case 200:
									this.forgotSuccess();
									break;
								default:
									this.uiService.showMessage(
										response.json()['message']
									);
									break;
							}

							this.loading = false;
							this.cdr.markForCheck();
						}, (error: Response) => {
							this.loading = false;
							this.uiService.showMessage(error.json()['message']);
							this.cdr.markForCheck();
						}); // END POST
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	forgotSuccess() {
		this.showForgotModal = 1;
		this.configForgotModal = {
			status: 1,
			icono: 'smart-icon-check',
			titulo: 'Revisa tu bandeja de entrada',
			textos: [
				`Hemos enviado un correo a <b>${this.email}</b> <br> Allí encontrarás las instruciones para restablecer tu contraseña`
			],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'ok'
				}
			]
		};
		this.cdr.markForCheck();
	}

	changeStatus() {
		this.showForgotModal = -1;
		this.router.navigateByUrl(`/auth/login`);
	}
}
