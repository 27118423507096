export class UserDTO {

    username: string;
    roles: string[];
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;

    constructor(data?: any) {
        if (data !== undefined) {
            this.username = data['username'] !== undefined ? data['username'] : null;
            this.roles = data['roles'] !== undefined ? data['roles'] : null;
            this.access_token = data['access_token'] !== undefined ? data['access_token'] : null;
            this.refresh_token = data['refresh_token'] !== undefined ? data['refresh_token'] : null;
            this.token_type = data['token_type'] !== undefined ? data['token_type'] : null;
            this.expires_in = data['expires_in'] !== undefined ? data['expires_in'] : null;
        }
    }

    static fromJS(data: any): UserDTO {
        return new UserDTO(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['username'] = this.username !== undefined ? this.username : null;
        data['roles'] = this.roles !== undefined ? this.roles : null;
        data['access_token'] = this.access_token !== undefined ? this.access_token : null;
        data['refresh_token'] = this.refresh_token !== undefined ? this.refresh_token : null;
        data['token_type'] = this.token_type !== undefined ? this.token_type : null;
        data['expires_in'] = this.expires_in !== undefined ? this.expires_in : null;
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new UserDTO(JSON.parse(json));
    }

}
