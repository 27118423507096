import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalMensajeComponent } from '../../components/modal-mensaje/modal-mensaje.component';
import { LoaderComponent } from '../../components/loader/loader.component';
import { NotificationsComponent } from '../../components/notifications/notifications.component';

import { PartialsModule } from '../../partials/partials.module';
import { ModalComponent } from '../../components/modal/modal.component';
import { TablasComponent } from '../../components/tablas/tablas.component';
import { SelectListComponent } from '../../components/select-list/select-list.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule, MatRadioModule, MatDatepickerModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

@NgModule({
	declarations: [
		ModalMensajeComponent,
		ModalComponent,
		LoaderComponent,
		TablasComponent,
		SelectListComponent,
		NotificationsComponent

	],
	exports: [
		ModalMensajeComponent,
		ModalComponent,
		LoaderComponent,
		TablasComponent,
		SelectListComponent,
		NotificationsComponent
	],
	imports: [
		CommonModule,
		PartialsModule,

		FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
		MatCheckboxModule,
		MatRadioModule,
		MatDatepickerModule,
		MatFormFieldModule,
        MatInputModule,
		PerfectScrollbarModule,
		NgbModule,
		HttpClientModule,
		HttpClientJsonpModule
	],
    entryComponents: []
})
export class SharedModule {
}
