// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ColorChangingSvgComponent } from './views/car/aside-right/color-changing-svg/color-changing-svg.component';

const routes: Routes = [
	{path: 'color-svg', component: ColorChangingSvgComponent},
	{path: '', redirectTo: 'map', pathMatch: 'full'},
	{path: 'auth', loadChildren: 'app/views/shared/pages/auth/auth.module#AuthModule'},
	{path: 'map', loadChildren: 'app/views/car/map.module#MapModule'},
	{path: 'admin', loadChildren: 'app/views/administrador/administrador.module#AdministradorModule' },
	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
