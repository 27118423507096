import { Component, PipeTransform, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UIService } from '@app/views/car/_services/UI.service';


@Component({
  selector: 'kt-tablas',
  templateUrl: './tablas.component.html',
  styleUrls: ['./tablas.component.scss'],
  providers: [DecimalPipe]
})
export class TablasComponent implements OnInit {
  @Input() config: object;
  configTable: object;
  leftsColumns: any = [];
  hideColumns: any = [];
  typeColumn: any = [];
  showExport: boolean;
  showPag: boolean;
  showSearch: boolean;
  searchTitle: string;
  permission: any;
  title:any;

  totalPages: number = 0;
  maxPages: number = 0;
  status: number = -1;
  disableEdit: boolean = false;

  data$: Observable<any>;
  filter = new FormControl('');

  paginadorIndex = -1;
  paginas: number[] = [];

  showGra:boolean=false;

  // PROGRAMACION
  @Input('content')
	set content( varsContent ) {
    this.status = varsContent;
  }

  @Input('configTabla')
  set configTabla( varConfig ) {

    this.paginadorIndex = -1;
    this.paginas = [];

    if ( varConfig['status'] > 0 ) {
      this.configTable = varConfig;

      if ( varConfig && varConfig['leftsColumns'] ) {
        this.leftsColumns = varConfig['leftsColumns'];
      }
      if ( varConfig && varConfig['permissions'] ) {
        this.permission = varConfig['permissions'];
      }
      if ( varConfig && varConfig['hideColumns'] ) {
        this.hideColumns = varConfig['hideColumns'];
      }

      if ( varConfig && varConfig['typeColumn'] ) {
        this.typeColumn = varConfig['typeColumn'];
      }

      if ( varConfig && varConfig['showExport'] ) {
        this.showExport = varConfig['showExport'];
      }

      if ( varConfig && varConfig['showPag'] ) {
        this.showPag = varConfig['showPag'];
      }

      if ( varConfig && varConfig['showSearch'] ) {
        this.showSearch = varConfig['showSearch'];
      }

      if ( varConfig && varConfig['searchTitle'] ) {
        this.searchTitle = varConfig['searchTitle'];
      }

		if ( varConfig && varConfig['title'] ) {
			this.title = varConfig['title'];
		}

      if ( varConfig && varConfig['paginador'] && varConfig['paginador']['total'] ) {
        this.totalPages = Math.ceil(varConfig['paginador']['total'] / varConfig['paginador']['count']);
      }

      if ( varConfig && varConfig['paginador'] && varConfig['paginador']['maxPages'] ) {
        this.maxPages = varConfig['paginador']['maxPages'];

        if (this.totalPages <= this.maxPages ) {
          this.maxPages = this.totalPages;
        }

        for (let i = 1; i <= this.maxPages; i++) {
          if ( varConfig['paginador']['page'] < i) {
            this.paginadorIndex = 0;
          }
          else if(varConfig['paginador']['page'] === i && varConfig['paginador']['page'] === this.totalPages) {
            this.paginadorIndex = 0;
          }
        }

        if (this.paginadorIndex === -1) {
          this.paginadorIndex = varConfig['paginador']['page'] - Math.ceil(this.maxPages / 2);
        }
        let diferencia = (this.totalPages - this.paginadorIndex)

        if(diferencia < this.maxPages) {
          this.paginadorIndex =  this.paginadorIndex - (this.maxPages - diferencia);
        }

        for (let x = this.paginadorIndex; x < (this.paginadorIndex + this.maxPages); x++) {
          if ( x + 1 <= this.totalPages ) {
            this.paginas.push(x + 1);
          }
        }
      }
      
      if (varConfig && varConfig['disableEdit']) {
        this.disableEdit = varConfig['disableEdit']
      }

      this.llenarData();
    }
  }

  @Output() eventPagina  = new EventEmitter();
  @Output() eventLinkClick  = new EventEmitter();
  @Output() eventNuevoClick  = new EventEmitter();
  @Output() eventEditarClick  = new EventEmitter();
  @Output() eventEliminarClick  = new EventEmitter();
  @Output() eventCredencialesClick  = new EventEmitter();
  @Output() eventGestionCasoClick  = new EventEmitter();
  @Output() eventLocalizarClick  = new EventEmitter();
  @Output() eventGestionarIncidenteClick  = new EventEmitter();
  @Output() eventEstadoClick  = new EventEmitter();
  @Output() eventExportClick  = new EventEmitter();
  @Output() eventVideosClick  = new EventEmitter();
  @Output() eventAdjuntarClick  = new EventEmitter();
  @Output() eventVerCasoClick  = new EventEmitter();
  @Output() eventReasignarCasoManu  = new EventEmitter();
  @Output() eventReasignarCasoAuto  = new EventEmitter();
  @Output() eventResetPassword  = new EventEmitter();
  @Output() eventVerNotasClick  = new EventEmitter();
  @Output() eventViewDetailNote  = new EventEmitter();
  constructor(
		private _UIService: UIService,) {
    this.llenarData();
  }

  llenarData() {
    this.data$ = this.filter.valueChanges.pipe(
      startWith(''),
      map(text => this.search(text))
    );
  }

  checkEnlace(enlaces:any){
	  if(typeof  enlaces == 'string') {
		  var includesLinkCode = enlaces.includes('#####//#####');
		  var includeshttp = enlaces.includes('http');
		  return includesLinkCode && includeshttp
	  }
	  else{
		  return false;
	  }
  }
  getPDF(enlaces:any) {
      window.open(enlaces.split(" ", 4)[1])
      return enlaces.split(" ", 4)[1];
  }
  getConsulta(enlaces:any) {
      window.open(enlaces.split(" ", 4)[3]);
      return enlaces.split(" ", 4)[3];
  }
  getXLSX(enlaces:any) {
      window.open(enlaces.split(" ", 6)[5]);
      return enlaces.split(" ", 6)[5];
  }

  search(text: string): Array<any> {
    return this.configTable['data'].filter(itemData => {
      const term = text.toLowerCase();
      for (let s in this.configTable['searchColumn']) {
        for ( let iDa in itemData ) {
          if ( this.configTable['searchColumn'][s] == iDa && itemData[iDa].toLowerCase().includes(term) ) {
            return true;
          }
        }
      }
    });
  }

  ngOnInit() {

    if ( this.config && this.config['leftsColumns'] ) {
      this.leftsColumns = this.config['leftsColumns'];
    }
    if ( this.config && this.config['hideColumns'] ) {
      this.hideColumns = this.config['hideColumns'];
    }

    if ( this.config && this.config['typeColumn'] ) {
      this.typeColumn = this.config['typeColumn'];
    }

    if ( this.config && this.config['showExport'] ) {
      this.showExport = this.config['showExport'];
    }

    if ( this.config && this.config['showPag'] ) {
      this.showPag = this.config['showPag'];
    }

    if ( this.config ) {
      this.configTable = this.config;
    }

    // this.configTable = {
    //   header: ['ID', 'Nombre Operador', 'Nombre Perfil', 'Rol del Perfil', 'Phasellus', 'Acciones'],
    //   typeColumn: [,,,,,{ tipo: 'acciones', acciones: ['nuevo','editar', 'eliminar', 'credenciales']} ],
    //   searchColumn: [0, 1, 2],
    //   hideColumns: [0],
    //   configColumns: {
    //     id: 0,
    //     columnClick: 1,
    //   },
    //   data: [
    //     ['1', 'Operador 1', 'Natoque agam', 'Siter commodo', 'asd', ''],
    //   ]
    // }
  }

  accionClick( item ) {
    this.eventLinkClick.emit( item[this.configTable['configColumns']['id']] );
    console.log( `accionClick ${ item }` );
  }

  accionNuevo( item ) {
    this.eventNuevoClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionEditar( item ) {
    this.eventEditarClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionCredenciales( item ) {
    this.eventCredencialesClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionEliminar( item ) {
    this.eventEliminarClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionGestionCaso( item ) {
    this.eventGestionCasoClick.emit( item[this.configTable['configColumns']['id']] );
  }

  viewEstadoClick( item ) {
    this.eventLinkClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionLocalizar( item ) {
    this.eventLocalizarClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionGestionIncidente( item ) {
    this.eventGestionarIncidenteClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionReasignarCasoManu( item ){
    // this.eventReasignarCasoManu.emit( { item: item } );
    this.eventReasignarCasoManu.emit( item[this.configTable['configColumns']['id']] );
  }

  accionReasignarCasoAuto( item ){
    this.eventReasignarCasoAuto.emit( { item: item } );
  }

  accionEstado( item, value ) {

    this._UIService.showMessage(`Se realizó el cambio de estado del item ${item[this.configTable['configColumns']['id']]} a ${value? 'Activo': 'Inactivo'}`);
    this.eventEstadoClick.emit( { id: item[this.configTable['configColumns']['id']], value: value } );
  }

  accionExport(type) {
    this.eventExportClick.emit(type);
  }

  showPage(page: number) {
    this.eventPagina.emit(page);
  }

  accionVerVideos( item ) {
    this.eventVideosClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionAdjuntar( item ) {
    this.eventAdjuntarClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionVerCaso( item ) {
    this.eventVerCasoClick.emit( item[this.configTable['configColumns']['id']] );
  }

  mostrarGrafica(){
    this.showGra=!this.showGra;
  }

  accionResetPassword(item) {
    this.eventResetPassword.emit( item[this.configTable['configColumns']['email']] );
  }

  accionVerNotas(item) {
    this.eventVerNotasClick.emit( item[this.configTable['configColumns']['id']] );
  }

  accionViewDetailNote(item) {
    this.eventViewDetailNote.emit( item[this.configTable['configColumns']['id']] );
  }
}
